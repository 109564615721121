














































































































































































import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Emit, Model, Watch } from "vue-property-decorator";
import LaddaButton from "@/components/LaddaButton.vue";
import DropdownControl from "@/components/DropdownControl.vue";
import ConfirmRemoveItemModal from "../components/ConfirmRemoveItemModal.vue";
import { ApiHelper } from "@/helpers/all";
import { notifier } from "../models/common";

declare const $: any;

@Component({
  inheritAttrs: false,
  components: {
    ConfirmRemoveItemModal,
    LaddaButton,
    DropdownControl
  }
})
export default class CustomerProfitTargetModal extends TSXComponent<void> {
  $refs!: {
    modal: HTMLDivElement;
  };

  @Prop({ required: false, default: [] })
  profitTargets!: any;

  @Prop({ required: false, default: 0 })
  aID!: number;

  @Prop({ required: false, default: [] })
  targetBusinessLineOptions!: any;

  @Prop({ required: false, default: {} })
  editTargetItem!: any;

  $parent: any;
  $systemGroups: any;
  $validator: any;
  saving: boolean = false;
  currentYear: number = new Date().getFullYear();
  target_year: number = this.currentYear;
  target_profit: number = 0;
  selectedTargetBusinessLine: number = 0;
  target_notes: string = "";
  confirmTargetDelete: boolean = false;
  deletedTarget: any = {};
  isEditTarget: boolean = false;
  isTargetListPage: boolean = false;
  targetUUID: string = "";
  target_active: boolean = false;

  mounted() {
    $(this.$refs.modal).modal("show");

    $(this.$refs.modal).on("hide.bs.modal", () => {
      this.$emit("close");
    });
  }

  beforeDestroy() {
    $(this.$refs.modal).modal("hide");
  }

  created() {
    if (this.editTargetItem.AID) {
      this.aID = this.editTargetItem.AID;
    }
    if (this.editTargetItem.ISEDITTARGET) {
      this.isEditTarget = this.editTargetItem.ISEDITTARGET;
    }
    if (this.editTargetItem.ISTARGETLISTPAGE) {
      this.isTargetListPage = this.editTargetItem.ISTARGETLISTPAGE;
    }
    if (this.editTargetItem.TARGET_YEAR) {
      this.target_year = this.editTargetItem.TARGET_YEAR;
    }
    if (this.editTargetItem.TARGET_PROFIT) {
      this.target_profit = this.editTargetItem.TARGET_PROFIT;
    }
    if (this.editTargetItem.TARGET_NOTES) {
      this.target_notes = this.editTargetItem.TARGET_NOTES;
    }
    if (this.editTargetItem.BUSINESSLINEID) {
      this.selectedTargetBusinessLine = this.editTargetItem.BUSINESSLINEID;
    }
    if (this.editTargetItem.TARGETUUID) {
      this.targetUUID = this.editTargetItem.TARGETUUID;
    }
  }

  async save() {
    const result = await this.$validator.validateAll();
    if (result && this.aID) {
      let activeTarget = this.checkTarget();
      if (activeTarget) {
        this.errorMsgActiveTarget();
        return;
      }
      if (this.target_year > (this.currentYear+1)) {
        notifier.alert(`You can only set the target year till ${this.currentYear+1}.`);
        return;
      }
      this.saving = true;
      let businessLineName = "";
      let getSelectedBLdetail = this.targetBusinessLineOptions.find((item: any) => item.ID == this.selectedTargetBusinessLine);
      if (getSelectedBLdetail && getSelectedBLdetail.TEXT) {
        businessLineName = getSelectedBLdetail.TEXT;
      }
      const response = await ApiHelper.callApi("post", {
        controller: "Customers",
        FunctionName: "UpdateProfitTargets",
        aID: this.aID,
        target_year: this.target_year,
        target_profit: this.target_profit,
        target_notes: this.target_notes,
        action: "insert",
        businessLineId: this.selectedTargetBusinessLine,
        businessLineName: businessLineName,
        isTargetListPage: this.isTargetListPage
      });

      if (response.STATUS === 1) {
        notifier.success(response.STATUSMESSAGE);
        // if (response.NEW_TARGET_PROFIT) {
        //   this.$parent.profit_targets.push(response.NEW_TARGET_PROFIT);
        // }
        // if (response.CY_TARGET) {
        //   this.$parent.cy_target = response.CY_TARGET;
        // }
        if (this.isTargetListPage) {
          this.$emit("updateTarget");
        } else {
          this.$parent.initialLoad(-1);
          this.$emit("close");
        }
      } else {
        notifier.alert(response.STATUSMESSAGE);
      }
      this.saving = false;
    }
  }

  async changeTargetActive(item) {
    if (!item.ISACTIVE) {
      let activeTarget = this.checkTarget(item.TARGET_YEAR, item.TARGETID, item.BUSINESSLINEID);
      if (activeTarget) {
        this.errorMsgActiveTarget();
        return;
      }
    }
    item.ISACTIVE = !item.ISACTIVE;
    const response = await ApiHelper.callApi("post", {
      controller: "Customers",
      FunctionName: "UpdateProfitTargets",
      aID: this.aID,
      isActive: item.ISACTIVE,
      targetUUID: item.TARGETUUID,
      action: "updateTargetActive"
    });
    if (response.STATUS === 1) {
      notifier.success(response.STATUSMESSAGE);
      // if (response.CY_TARGET) {
      //   this.$parent.cy_target = response.CY_TARGET;
      // }
      this.$parent.initialLoad(-1);
    } else {
      notifier.alert(response.STATUSMESSAGE);
    }
  }

  checkTarget(year = this.target_year, id = 0, businessLineId = this.selectedTargetBusinessLine) {
    let checkedAllBL = false;
    if (!businessLineId) {
      checkedAllBL = true;
    }
    if (checkedAllBL) {
      return this.profitTargets.filter((item: any) => year == item.TARGET_YEAR && id != item.TARGETID && item.ISACTIVE && (businessLineId == item.BUSINESSLINEID || item.BUSINESSLINEID != 0)).length ? true : false;
    }
    return this.profitTargets.filter((item: any) => year == item.TARGET_YEAR && id != item.TARGETID && item.ISACTIVE && (businessLineId == item.BUSINESSLINEID || item.BUSINESSLINEID == 0)).length ? true : false;
  }

  errorMsgActiveTarget() {
    notifier.alert("You have already set the target for this year and business line. If you want to set a new target, Please turn off the existing Active Target.");
  }

  updateAllBusinessLine(id) {
    this.selectedTargetBusinessLine = id;
  }

  toggleDeleteModal(item) {
    this.deletedTarget = item;
    this.confirmTargetDelete = true;
  }

  async deleteTarget(item) {
    const response = await ApiHelper.callApi("post", {
      controller: "Customers",
      FunctionName: "UpdateProfitTargets",
      aID: this.aID,
      targetUUID: item.TARGETUUID,
      action: "delete"
    });
    if (response.STATUS == 1) {
      this.confirmTargetDelete = false;
      this.deletedTarget = {};
      notifier.success(response.STATUSMESSAGE);
      this.$parent.initialLoad(-1);
    } else {
      notifier.success("Error Occurred");
    }
  }

  async edit() {
    const result = await this.$validator.validateAll();
    if (result && this.aID) {
      this.saving = true;
      const response = await ApiHelper.callApi("post", {
        controller: "Customers",
        FunctionName: "UpdateProfitTargets",
        aID: this.aID,
        target_year: this.target_year,
        target_profit: this.target_profit,
        target_notes: this.target_notes,
        action: "updateProfitTarget",
        businessLineId: this.selectedTargetBusinessLine,
        targetUUID: this.targetUUID,
        isUpdateTargetBL: true
      });

      if (response.STATUS === 1) {
        notifier.success(response.STATUSMESSAGE);
        this.$emit("updateTarget");
      } else {
        notifier.alert(response.STATUSMESSAGE);
      }
      this.saving = false;
    }
  }
}
