




















































































































































































































































































































































































import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Watch } from "vue-property-decorator";
import LaddaButton from "../components/LaddaButton.vue";
import PageTitle from "../components/pageTitle.vue";
import SelectTagControl from "@/components/SelectTagControl.vue";
import HardwaresImportPreviewModal from "@/components/HardwaresImportPreviewModal.vue";
import { notifier } from "../models/common";
import axios from "axios";

@Component({
  inheritAttrs: false,
  components: {
    SelectTagControl,
    HardwaresImportPreviewModal,
    LaddaButton,
    PageTitle
  }
})
export default class HardwareImportNew extends TSXComponent<void> {

  csvColumnOptions: any = [];

  dbColumnOptions: any = [];

  formStep: number = 0;
  mappingCSVToDatabase: any = [];

  uploading: boolean = false;
  loadingPreview: boolean = false;
  loadingImport: boolean = false;

  csvFileName: string = '';
  csvRows: any[] = [];
  csvDBRows: any[] = [];

  confirmList: any[] = [];

  previewInsertedCount: number = 0;
  previewUpdatedCount: number = 0;
  previewSkippedCount: number = 0;

  importInsertedCount: number = 0;
  importUpdatedCount: number = 0;
  importSkippedCount: number = 0;
  importFailedCount: number = 0;
  importConfigurationsCount: number = 0;

  addToCustomerId = '';
  accountList: any = [];

  showHardwaresImportPreviewFlg = false;
  importHardwareIds: any = [];
  requiredHeader: any = ['SKU','Description','SerialNumber','PONumber'];
  allOrders: any[] = [];
  checkSerialNumberDuplicate: any[] = [];
  isCheckDynamicPurchaseID: boolean = false;
  tryLinkCatId = true;
  fullCustomData: any[] = [];

  get csvColumnCount() {
    const csvColumns = this.csvColumnOptions.filter((map) => map.ID);
    if (csvColumns.length > 0) {
      return csvColumns.length;
    }

    return 0;
  }

  async created() {
    this.getAccountList();
    this.refreshMappingRows();
  }

  initMappingRows() {
    this.mappingCSVToDatabase = [];
    for (let rowIdx = 0; rowIdx < 3; rowIdx++) {
      this.mappingCSVToDatabase.push({
        rowIndex: rowIdx,
        csvColumnId: '',
        dbColumnId: '',
        isKey: false,
        isUpdateKey: false,
        isCustomData: false,
        customFieldId: 0
      })
    }
  }

  isDuplicatedMappingDBColumn(choiceDBColumn) {
    if (choiceDBColumn) {
      const findColumn = this.mappingCSVToDatabase.filter((map) => map.dbColumnId === choiceDBColumn && map.dbColumnId);
      if (findColumn.length > 1) {
        return true;
      }
    }

    return false;
  }

  refreshMappingRows(allowEmptyCSV = true, index = 0) {
    if (index == this.mappingCSVToDatabase.length) {
      let notEmptyRows = this.mappingCSVToDatabase;
      if (allowEmptyCSV) {
        notEmptyRows = this.mappingCSVToDatabase.filter((map) => map.csvColumnId);
      }
  
      // update row index
      notEmptyRows = notEmptyRows.map((row, index) => ({
        ...row,
        rowIndex: index
      }));
  
      let currentRowIdx = notEmptyRows.length;
      const maxAllowedRowCount = currentRowIdx + 1;
  
      for (let rowCount = notEmptyRows.length; rowCount < maxAllowedRowCount; rowCount++) {
        // append new empty row
        currentRowIdx++
        notEmptyRows.push({
          rowIndex: currentRowIdx,
          csvColumnId: '',
          dbColumnId: '',
          isKey: false,
          isUpdateKey: false,
          isCustomData: false,
          customFieldId: 0
        })
      }
  
      this.mappingCSVToDatabase = notEmptyRows;
    }
  }

  async getAccountList() {
    try {
      const response = await axios.post(dataURL + "?ReturnType=JSON", {
        controller: "Queries",
        FunctionName: "GetAllAccounts",
        subsystem: "Helpers"
      });

      if (response.data.ACCOUNTLIST) {
        const accountList = response.data.ACCOUNTLIST;

        this.accountList = accountList.map((account) => ({
          ID: account.AID,
          TEXT: account.ANAME
        }));
      }

    } catch (err) {
      console.log(err);
    }
  }

  chooseCsvColumn(columnId, rowIdx) {
    this.mappingCSVToDatabase[rowIdx].csvColumnId = columnId;

    this.refreshMappingRows(false, rowIdx+1);
  }

  chooseDBColumn(columnId, rowIdx) {
    this.mappingCSVToDatabase[rowIdx].dbColumnId = columnId;
    let findCD = this.fullCustomData.filter(item => item.CUSTOMFIELDNAME == columnId);
    this.mappingCSVToDatabase[rowIdx].isCustomData = findCD.length ? true : false;
    this.mappingCSVToDatabase[rowIdx].customFieldId = findCD.length ? findCD[0].CUSTOMFIELDID : 0;
  }

  chooseAccount(accountId, rowIdx) {
    this.addToCustomerId = accountId;
  }

  async saveHardwaresImportPreview(updateConfirmList) {
    this.showHardwaresImportPreviewFlg = false;
    this.formStep = 1;

    // reset import statistics
    this.importInsertedCount = 0;
    this.importUpdatedCount = 0;
    this.importSkippedCount = 0;
    this.importFailedCount = 0;
    this.importConfigurationsCount = 0;

    try {
      this.loadingPreview = true;

      let updatedRows;
      updatedRows = updateConfirmList.filter((udp) => ["PONumber", "VAR360 Order"].includes(udp.dbColumnId));
      if (updatedRows.length) {
        for (const dbRow of this.csvDBRows) {
          dbRow.skipFlg = updatedRows[0].skipFlg;
          if (this.isCheckDynamicPurchaseID && typeof updatedRows[0]["purchaseIDs"] != "undefined" && updatedRows[0]["purchaseIDs"].length && updatedRows[0].updateValue != 0) {
            dbRow["purchaseID"] = updatedRows[0].updateValue.toString();
          }
        }

        // po not found case
        const poNotFounds = updatedRows.filter(t => t.poNotFound || 0);
        for(const item of poNotFounds) {
          const inList = this.csvDBRows.filter(t => (item.lineNos || []).includes(t.lineNo));
          for(const t of inList) {
            t.poNotFound = 1;

            // check if item is in confirmList (not poNotFound case)
            const inConfirmList = updateConfirmList.find(t1 => t1.lineNo == t.lineNo && !(t1.poNotFound || 0));
            if(!inConfirmList) {
              // not in confirm list, follow skipFlg of the item has poNotFound
              t.skipFlg = item.skipFlg;
            }else {
              // in confirm list (not poNotFound case), follow skipFlg on this line
              t.skipFlg = inConfirmList.skipFlg;
            }
            
            if(!t.skipFlg) {
              // auto add poId for this line
              t.PONumber = item.currentValue || "";
            }
          }
        }

        // PO Required
        const poRequireds = updatedRows.filter(t => t.poRequired || 0);
        for(const item of poRequireds) {
          const inList = this.csvDBRows.filter(t => !(t.PONumber || 0));
          for(const t of inList) {
            if(!item.skipFlg) {
              // auto add poId for this line
              t.PONumber = item.updateValue || "";
            }
          }
        }
      } else {
        for (const dbRow of this.csvDBRows) {
          updatedRows = updateConfirmList.filter((udp) => udp.lineNo === dbRow.lineNo);
          dbRow.skipFlg = 0;
          for (const updatedRow of updatedRows) {
            if (updatedRow.skipFlg === 0) {
              dbRow[updatedRow.dbColumnId] = updatedRow.updateValue.toString();
            } else {
              dbRow.skipFlg = 1;
            }
          }
        }
      }

      const validMappingCSVToDB = this.mappingCSVToDatabase.filter((map) => map.csvColumnId && map.dbColumnId);

      let UpdateKeyArr = validMappingCSVToDB.filter((fmap) => fmap.isUpdateKey && !fmap.isCustomData).map((map) => map.dbColumnId);
      let KeyArr = validMappingCSVToDB.filter((fmap) => fmap.isKey).map((map) => map.dbColumnId);
      let UpdateKeyCDArr = validMappingCSVToDB.filter((fmap) => fmap.isUpdateKey && fmap.isCustomData).map((map) => map.dbColumnId);
      if ((UpdateKeyArr.length || UpdateKeyCDArr.length) && !KeyArr.length) {
        notifier.alert("Please select a Key");
        return false;
      } else if (!(UpdateKeyArr.length || UpdateKeyCDArr.length) && KeyArr.length) {
        notifier.alert("Please select a Update on Key");
        return false;
      }

      let csvHeaderArr = validMappingCSVToDB.filter((fmap) => !fmap.isCustomData).map((map) => map.dbColumnId);
      
      const csvKeyOnUpdate = validMappingCSVToDB.filter((fmap) => fmap.isKey && !fmap.isCustomData).map((map) => map.dbColumnId);
      const response = await axios.post(dataURL + "?ReturnType=JSON", {
        controller: 'Hardware',
        FunctionName: 'ImportHardwaresNew',
        stepName: 'Preview',
        csvFileName: this.csvFileName,
        accountId: this.addToCustomerId,
        mappingCSVToDatabase: validMappingCSVToDB,
        csvHeader: UpdateKeyArr.length && KeyArr.length ? UpdateKeyArr : csvHeaderArr,
        csvKeyOnUpdate,
        csvData: this.csvDBRows,
        csvCDHeader: UpdateKeyCDArr
      });

      // console.log('preview response ', response);
      const message = response.data.STATUSMESSAGE || "";
      if (response.data.STATUS == 1) {
        this.previewInsertedCount = response.data.insertedCount;
        this.previewUpdatedCount = response.data.updatedCount;
        this.previewSkippedCount = response.data.skippedCount;
        this.formStep = 2;

        // show notify if no data to insert or update
        if(message) {
          notifier.warning(message);
        }
      } else if (message) {
        notifier.alert(message);
      }
    } catch (error) {
      console.log('preview error ', error);
    } finally {
      this.loadingPreview = false;
    }
  }

  showFileUpload() {
    $("#hardwareFileUpload").click();
  }

  async handleHardwareFileUpload(e) {
    e.stopPropagation();
    e.preventDefault();

    const fileName = e.target.files[0].name;
    const allowedExtensions = /(\.csv)$/i;
    if (!allowedExtensions.exec(fileName)) {
      notifier.alert("Please upload file having extensions .csv only.");
      return false;
    }

    try {
      this.uploading = true;

      const requestObj = {
        Controller: "Hardware",
        FunctionName: "ImportHardwaresNew",
        stepName: 'LoadCSVFile',
        filePath: e.target.files[0],
        fileName,
        fileType: 'csv'

      };

      const formData = new FormData();
      for (let key in requestObj) {
        formData.append(key, requestObj[key]);
      }

      formData.append("session", sessionStorage.getItem("sessionID") || "");
      const response: any = await axios.post(
        dataURL + "?ReturnType=JSON",
        formData,
        {
          headers: {
            "content-type": "multipart/form-data"
          }
        }
      );

      if (response.data.STATUS == 1) {

        // show list column and data on screen
        const headers = response.data.headers;
        const rows = response.data.rows;
        const dbColumns = response.data.dbColumns;
        const importHardwareColumns = response.data.importHardwareColumns || {};
        this.fullCustomData = response.data.CUSTOMDEFINITION || [];
        this.csvFileName = response.data.csvFileName
        this.allOrders = response.data.allOrders || [];
        this.checkSerialNumberDuplicate = response.data.checkSerialNumberDuplicate || [];
        let customFieldData = this.fullCustomData.map(item => ({ 'name': item.CUSTOMFIELDNAME, 'id': item.CUSTOMFIELDID }));

        headers.unshift('');

        if (this.fullCustomData.length) {
          this.fullCustomData.map((item: any) => {
            dbColumns.push(item.CUSTOMFIELDNAME);
          });
        }

        this.csvColumnOptions = headers.map((header) => ({
          ID: header,
          TEXT: header
        }));

        this.dbColumnOptions = [];
        for(const col of dbColumns) {
          // override some text in dropdown "Var360 Column Name"
          let text = col;
          if(col == "purchaseID") {
            text = "Var360 Order";
          }else if(col == "startDate") {
            text = "Warranty Start Date";
          }
          this.dbColumnOptions.push({
            ID: col,
            TEXT: text
          });
        }

        this.csvRows = rows.map((row, index) => ({
          lineNo: index+1,
          ...row
        }));

        if (dbColumns.length) {
          this.mappingCSVToDatabase = [];
          this.requiredHeader.map((requiredItem: any, index: number) => {
            let checkDuplicateHeader = this.mappingCSVToDatabase.filter((mappingItem: any) => mappingItem.dbColumnId == requiredItem);
            
            let csvColumnId = '';
            if (importHardwareColumns[requiredItem]) {
              importHardwareColumns[requiredItem].split(",").map((importItem: any) => {
                if (headers.includes(importItem)) {
                  csvColumnId = importItem;
                }
              });
            }

            if (!checkDuplicateHeader.length) {
              this.mappingCSVToDatabase.push({
                rowIndex: index,
                csvColumnId: csvColumnId,
                dbColumnId: requiredItem,
                isKey: false,
                isUpdateKey: false,
                isCustomData: false,
                customFieldId: 0
              });
            }
          });
        }
        
        if (Object.keys(importHardwareColumns).length) {
          Object.keys(importHardwareColumns).map((dbColumnNames: string) => {
            let checkMappingColumn = this.mappingCSVToDatabase.filter((mappingItem: any) => mappingItem.dbColumnId == dbColumnNames);
            let csvColumnId = '';
            if (!checkMappingColumn.length && importHardwareColumns[dbColumnNames]) {
              importHardwareColumns[dbColumnNames].split(",").map((importItem: any) => {
                if (headers.includes(importItem)) {
                  csvColumnId = importItem;
                }
              });
              if (csvColumnId.length) {
                let findCD = customFieldData.filter(item => item.name == dbColumnNames);
                this.mappingCSVToDatabase.push({
                  rowIndex: this.mappingCSVToDatabase.length+1,
                  csvColumnId: csvColumnId,
                  dbColumnId: dbColumnNames,
                  isKey: false,
                  isUpdateKey: false,
                  isCustomData: findCD.length ? true : false,
                  customFieldId: findCD.length ? findCD[0].id : 0
                });
              }
            }
          });
        }

        this.refreshMappingRows(false, this.mappingCSVToDatabase.length);
        this.formStep = 1; // Mapping CSV and DB
        // this.$refs.hardwareFileUpload.value = null;
        $("#hardwareFileUpload").val("");
      } else {
        const message = response.data.STATUSMESSAGE || "";
        if (message) {
          notifier.alert(message);
        }
      }

    } catch (error) {
      console.log('error ', error);
    } finally {
      this.uploading = false;
    }
  }

  async doPreview() {
    // console.log('doPreview popup');

    // convert to db column
    const csvDB: any[] = [];
    const confirmList: any[] = [];
    const duplicatePOList: any[] = [];
    let mapRow: any = null;
    let dbColumnName = null;

    try {
      const valid = await this.$validator.validate('Customer');

      if (!valid) {
        return;
      }

      const validMappingCSVToDB = this.mappingCSVToDatabase.filter((map) => map.csvColumnId && map.dbColumnId);

      if (validMappingCSVToDB.length === 0) {
        notifier.alert('Please map the CSV data to the Var360 column');
        return;
      }

      const validRequiredCSVToDB = this.mappingCSVToDatabase.filter((map) => (!map.csvColumnId && map.dbColumnId) || (map.csvColumnId && !map.dbColumnId));

      if (validRequiredCSVToDB.length) {
        notifier.alert('Please fill the required fields');
        return;
      }

      const validRequiredHeader = this.mappingCSVToDatabase.filter((item: any) => this.requiredHeader.includes(item.dbColumnId));
      const isUpdate = this.mappingCSVToDatabase.find((item: any) => item.isKey || item.isUpdateKey);

      if (!isUpdate && validRequiredHeader.length != this.requiredHeader.length) {
        let checkSKURequired = this.mappingCSVToDatabase.filter((mapItem: any) => mapItem.dbColumnId === 'SKU');
        if (!checkSKURequired.length) {
          notifier.alert("SKU column is required");
          return;
        }
        let checkSerialNumberRequired = this.mappingCSVToDatabase.filter((mapItem: any) => mapItem.dbColumnId === 'SerialNumber');
        if (!checkSerialNumberRequired.length) {
          notifier.alert("Serial Number column is required");
          return;
        }
        let checkPONumberRequired = this.mappingCSVToDatabase.filter((mapItem: any) => mapItem.dbColumnId === 'PONumber');
        if (!checkPONumberRequired.length) {
          notifier.alert("PO column is required");
          return;
        }
        let checkDescriptionRequired = this.mappingCSVToDatabase.filter((mapItem: any) => mapItem.dbColumnId === 'Description');
        if (!checkDescriptionRequired.length) {
          notifier.alert("Description is required");
          return;
        }
      }

      this.isCheckDynamicPurchaseID = false;
      let checkPurchaseIDExists = this.mappingCSVToDatabase.filter((mapItem: any) => mapItem.dbColumnId === 'purchaseID');
      if (!checkPurchaseIDExists.length) {
        this.isCheckDynamicPurchaseID = true;
      }

      let checkUpdateHardware = this.mappingCSVToDatabase.filter((item: any) => item.isKey || item.isUpdateKey);
      let checkSerialNo = true;
      let checkDuplicatePo = true;
      
      if (checkUpdateHardware.length) {
        checkSerialNo = false;
        checkDuplicatePo = false;
        let checkSerialNoColumn = checkUpdateHardware.filter((item: any) => item.dbColumnId === "SerialNumber" && item.isUpdateKey);
        if (checkSerialNoColumn.length) {
          checkSerialNo = true;
        }
        let checkDuplicateColumn = checkUpdateHardware.filter((item: any) => item.dbColumnId === "PONumber" && item.isUpdateKey);
        if (checkDuplicateColumn.length) {
          checkDuplicatePo = true;
        }
      }

      // limit checking duplicated serial for related customer PO in the csv file
      const poNumberMap = this.mappingCSVToDatabase.find(item => item.dbColumnId == 'PONumber');
      const csvPONumberField = poNumberMap ? (poNumberMap.csvColumnId || "") : "";
      const allPoIds: string[] = csvPONumberField ? [...new Set(this.csvRows.map(item => item[csvPONumberField]))] : [];
      const existedAssetNumber = this.checkSerialNumberDuplicate.filter(item => item.AID == this.addToCustomerId && (allPoIds.includes(item.POID) || allPoIds.includes(item.EXTERNALPOID)));

      for (const item of this.csvRows) {
        mapRow = {lineNo: item.lineNo};
        let tempCDArr: any = [];
        for (const mapColumn of this.mappingCSVToDatabase) {
          dbColumnName = mapColumn.dbColumnId;
          const value = item[mapColumn.csvColumnId];

          if (dbColumnName) {
            if (dbColumnName === 'SKU') {
              if (value) {
                mapRow[dbColumnName] = value;
              } else {
                confirmList.push({
                    lineNo: item.lineNo,
                    csvColumnId: mapColumn.csvColumnId,
                    dbColumnId: dbColumnName,
                    currentValue: value,
                    updateValue: '',
                    generatedValue: `SKU${item.lineNo.toString().padStart(4, '0')}`,
                    updateOriginValue: '',
                    errorMessage: `SKU column is required`,
                    skipFlg: 1
                  });
              }
            } else if (dbColumnName === 'WarrantyExp') {
              if (value) {
                const dateExp = new Date(value);

                if (Number.isNaN(dateExp.valueOf())) {
                  mapRow[dbColumnName] = null;

                  confirmList.push({
                    lineNo: item.lineNo,
                    csvColumnId: mapColumn.csvColumnId,
                    dbColumnId: dbColumnName,
                    currentValue: value,
                    updateValue: '',
                    generatedValue: '',
                    updateOriginValue: null,
                    errorMessage: `Invalid date in the ${dbColumnName} column`,
                    skipFlg: 1
                  });

                } else {
                  mapRow[dbColumnName] = dateExp.toISOString().slice(0, 10);
                }
              } else {
                mapRow[dbColumnName] = null;
              }
            } else if (['purchaseID'].includes(dbColumnName)) {
              // 'PurchaseOrderLineId'
              if (isNaN(value) || !Number.isSafeInteger(+value)) {
                confirmList.push({
                  lineNo: item.lineNo,
                  csvColumnId: mapColumn.csvColumnId,
                  dbColumnId: dbColumnName,
                  currentValue: value,
                  updateValue: '0',
                  generatedValue: '0',
                  updateOriginValue: '0',
                  errorMessage: `Only integer values are allowed in the ${dbColumnName} column`,
                  skipFlg: 1
                });
              } else {
                mapRow[dbColumnName] = value;
              }
            } else if (dbColumnName === 'Price') {
              const priceParts = value.toString().split('.');

              let priceNumberStr = '';
              for (let index = 0; index < priceParts.length; index++) {
                const partNumber = priceParts[index].replace(/[^\d]/g, '');

                if (index > 0 && index === priceParts.length - 1) {
                  priceNumberStr = `${priceNumberStr}.`; // append decimal point
                }

                priceNumberStr = `${priceNumberStr}${partNumber}`;
              }

              if (priceNumberStr) {
                mapRow[dbColumnName] = (+priceNumberStr).toString();
              } else {
                confirmList.push({
                  lineNo: item.lineNo,
                  csvColumnId: mapColumn.csvColumnId,
                  dbColumnId: dbColumnName,
                  currentValue: value,
                  updateValue: '0',
                  generatedValue: '0',
                  updateOriginValue: '0',
                  errorMessage: 'The Price column contains invalid value',
                  skipFlg: 1
                });
              }

              mapRow[dbColumnName] = '0';
            } else if (dbColumnName === 'PONumber' && this.allOrders.length) {
              mapRow[dbColumnName] = value;
              let checkPONumber = this.allOrders.filter((item: any) => item.POID == value);
              if (!value) {
                duplicatePOList.push({
                  lineNo: item.lineNo,
                  csvColumnId: mapColumn.csvColumnId,
                  dbColumnId: dbColumnName,
                  currentValue: value,
                  updateValue: '',
                  generatedValue: `PO${item.lineNo.toString().padStart(4, '0')}`,
                  updateOriginValue: `PO${item.lineNo.toString().padStart(4, '0')}`,
                  errorMessage: `PO column is required`,
                  skipFlg: 1,
                  poRequired: 1
                });
              } else if (!checkPONumber.length && checkDuplicatePo) {
                duplicatePOList.push({
                  lineNo: item.lineNo,
                  csvColumnId: mapColumn.csvColumnId,
                  dbColumnId: dbColumnName,
                  currentValue: value,
                  updateValue: '',
                  generatedValue: '',
                  updateOriginValue: '',
                  errorMessage: `PO Not Found in Var360`,
                  skipFlg: 1,
                  poNotFound: 1
                });
              } else if (this.isCheckDynamicPurchaseID && checkPONumber.length > 1 && (checkDuplicatePo || isUpdate)) {
                let purchaseIDs = checkPONumber.map(item => ({ "ID" : item.PURCHASEID, "TEXT" : item.PURCHASEID }));
                duplicatePOList.push({
                  lineNo: item.lineNo,
                  csvColumnId: mapColumn.csvColumnId,
                  dbColumnId: "VAR360 Order",
                  currentValue: value,
                  updateValue: 0,
                  generatedValue: '',
                  updateOriginValue: purchaseIDs[0]["ID"],
                  errorMessage: `Duplicate PO found, please select the correct order`,
                  skipFlg: 1,
                  purchaseIDs: purchaseIDs
                });
              } else {
                // mapRow[dbColumnName] = value;
                if (this.isCheckDynamicPurchaseID && checkPONumber.length) {
                  mapRow["purchaseID"] = checkPONumber[0]["ID"];
                }
              }
            } 
            else if(dbColumnName === 'SerialNumber' && existedAssetNumber.length && checkSerialNo) {
              mapRow[dbColumnName] = value;
              const inExisted = existedAssetNumber.find((t: any) => t.ASSETNUMBER.toUpperCase() == value.toUpperCase());
              if(inExisted) {
                confirmList.push({
                  lineNo: item.lineNo,
                  csvColumnId: mapColumn.csvColumnId,
                  dbColumnId: dbColumnName,
                  currentValue: value,
                  updateValue: '',
                  generatedValue: value,
                  updateOriginValue: '',
                  errorMessage: `Duplicate Serial Number found, please select an unique Serial Number`,
                  successMessage: 'This will create a Duplicate Serial Number',
                  skipFlg: 1
                });
              }
              // else {
              //   mapRow[dbColumnName] = value;
              // }
            } 
            else {
              let findCD = this.fullCustomData.filter(item => item.CUSTOMFIELDNAME == dbColumnName);
              if (findCD.length) {
                let cdData = findCD[0];
                let tempCDStruct = {};
                tempCDStruct['CUSTOMFIELDID'] = cdData.CUSTOMFIELDID;
                tempCDStruct['CUSTOMFIELDNAME'] = cdData.CUSTOMFIELDNAME;
                tempCDStruct['CUSTOMVALUE'] = value;
                tempCDStruct['CUSTOMID'] = 0;
                tempCDArr.push(tempCDStruct);
              } else {
                mapRow[dbColumnName] = value;
              }
            }
          }
        }
        if (tempCDArr.length) {
          mapRow['customData'] = tempCDArr;
        }

        csvDB.push(mapRow);
      }

      this.confirmList = confirmList;
      if (duplicatePOList.length) {
        let poNotFoundList = duplicatePOList.filter(item => !item.purchaseIDs);
        let poDuplicateList = duplicatePOList.filter(item => item.purchaseIDs);
        let poRequiredList = duplicatePOList.filter(item => item.poRequired);
        if (poNotFoundList.length) {
          // this.confirmList.push(poNotFoundList[0]);
          const processed: string[] = [];
          for(const item of poNotFoundList) {
            const poId = item.currentValue || "";
            if(!poId || processed.includes(poId)) {
              continue;
            }

            processed.push(poId);
            item.lineNos = poNotFoundList.filter(t => t.currentValue == poId).map(t => t.lineNo);
            this.confirmList.push(item);
          }
        }
        if (poDuplicateList.length) {
          this.confirmList.push(poDuplicateList[0]);
        }
        if (poRequiredList.length) {
          this.confirmList.push(poRequiredList[0]);
        }
      }

      this.csvDBRows = csvDB;

      if (confirmList.length > 0 || duplicatePOList.length) {
        this.showHardwaresImportPreviewFlg = true;
      } else {
        await this.saveHardwaresImportPreview(confirmList);
      }
    } catch (error) {
      console.log('doPreview error ', error);
    }
  }

  async doImport() {
    // Only do import if already check Preview
    // We also dont do Import if we don't have inserted and updated records
    if (this.formStep !== 2 || (this.previewInsertedCount === 0 && this.previewUpdatedCount === 0)) {
      notifier.warning(`No data can be inserted or updated. Please do Preview to confirm`);
      return;
    }

    // console.log('do Import');
    this.loadingImport = true;

    try {
      const valid = await this.$validator.validate('Customer');

      if (!valid) {
        return;
      }

      const validMappingCSVToDB = this.mappingCSVToDatabase.filter((map) => map.csvColumnId && map.dbColumnId);

      if (validMappingCSVToDB.length === 0) {
        notifier.alert('Please map the CSV data to the Var360 column');
        return;
      }
      let UpdateKeyArr = validMappingCSVToDB.filter((fmap) => fmap.isUpdateKey && !fmap.isCustomData).map((map) => map.dbColumnId);
      let KeyArr = validMappingCSVToDB.filter((fmap) => fmap.isKey).map((map) => map.dbColumnId);
      let UpdateKeyCDArr = validMappingCSVToDB.filter((fmap) => fmap.isUpdateKey && fmap.isCustomData).map((map) => map.dbColumnId);
      if ((UpdateKeyArr.length || UpdateKeyCDArr.length) && !KeyArr.length) {
        notifier.alert("Please select a Key");
        return false;
      } else if (!(UpdateKeyArr.length || UpdateKeyCDArr.length) && KeyArr.length) {
        notifier.alert("Please select a Update on Key");
        return false;
      }

      let csvHeaderArr = validMappingCSVToDB.filter((fmap) => !fmap.isCustomData).map((map) => map.dbColumnId);
      const isUpdate = validMappingCSVToDB.find((item: any) => item.isKey || item.isUpdateKey);
      const csvData = !isUpdate
        ? this.csvDBRows.filter(row => row.skipFlg === 0 && row.SKU.trim())
        : this.csvDBRows.filter(row => row.skipFlg === 0);

      const requestObj = {
        controller: 'Hardware',
        FunctionName: 'ImportHardwaresNew',
        stepName: 'Import',
        accountId: this.addToCustomerId,
        userID: sessionStorage.getItem("userId"),
        csvFileName: this.csvFileName,
        mappingCSVToDatabase: validMappingCSVToDB,
        csvHeader: UpdateKeyArr.length && KeyArr.length ? UpdateKeyArr : csvHeaderArr,
        csvKeyOnUpdate: validMappingCSVToDB.filter((fmap) => fmap.isKey && !fmap.isCustomData).map((map) => map.dbColumnId),
        csvData,
        tryLinkCatId: this.tryLinkCatId,
        csvCDHeader: UpdateKeyCDArr
      };

      const response = await axios.post(dataURL + "?ReturnType=JSON", requestObj);

      if (response.data.STATUS == 1) {
        this.importInsertedCount = this.previewInsertedCount;
        this.importUpdatedCount = this.previewUpdatedCount;
        this.importSkippedCount = this.previewSkippedCount;
        this.importFailedCount = 0;
        this.importConfigurationsCount = response.data.TOTAL_CONFIGURATIONS_IMPORTED;
        this.formStep = 3;
        this.importHardwareIds = response.data.IMPORTED_HARDWAREIDS || [];
      } else {
        this.importInsertedCount = 0;
        this.importUpdatedCount = 0;
        this.importSkippedCount = 0;
        this.importFailedCount = this.previewInsertedCount + this.previewUpdatedCount;
        this.importConfigurationsCount = 0;
        const message = response.data.STATUSMESSAGE || "";
        if (message) {
          notifier.alert(message);
        }
      }
    } catch (error) {
      console.log('doImport error ', error);
      this.importInsertedCount = 0;
      this.importUpdatedCount = 0;
      this.importSkippedCount = 0;
      this.importFailedCount = this.previewInsertedCount + this.previewUpdatedCount;
      this.importConfigurationsCount = 0;
    } finally {
      this.loadingImport = false;
    }
  }

  viewImportData() {
    this.$router.push({ name: "Hardware", params: {importHardwareIds: this.importHardwareIds || []} });
  }

  removeMappingColumns(index) {
    this.$delete(this.mappingCSVToDatabase, index);
  }
}
