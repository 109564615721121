




























































































































































































































































































import axios from "axios";
import Vue from "vue";
import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop } from "vue-property-decorator";
import { validateFn } from "../helpers";
import passwordModal from "../components/PasswordReset.vue";
import Loading from "@/components/Loading.vue";
import { notifier } from "../models/common";

declare let dataURL: string;
declare const $: any;

@Component({
  inheritAttrs: false,
  components: {
    Loading,
    passwordModal
  },
  methods: validateFn
})
export default class Login extends TSXComponent<void> {
  passwordModalVisible = false;
  userRole = "";
  accountname = "";
  portalLogo = "";
  primaryColor = "";
  checkExistShow = false;
  defaultDomain = false;
  errorMessage = "";
  showForgotPwd = false;
  showrembMe = false;
  rememberMe = localStorage.getItem("rememberMe") == "1" ? true : false;
  loading = false;
  check = false;
  password = "";
  confirmPassword = "";
  userEmail = "";
  passwordReset = false;
  $root: any;
  form2FAFVisible = false;
  code2FA: any = [
    { id: 1, val: "" },
    { id: 2, val: "" },
    { id: 3, val: "" },
    { id: 4, val: "" },
    { id: 5, val: "" },
    { id: 6, val: "" }
  ];
  userToken2FAF = "";
  verify2FAFFail = false;
  selectedEndpointId = 0;
  endpoints: any = [];

  constructor() {
    super();

    this.userRole = (function () {
      if (window.location.href.includes("localhost")) {
        // For dev purpose
        if (window.location.href.includes("var360customer")) {
          return "Customer";
        }
        return localStorage.getItem("userRole") || "Reseller";
      }

      return window.location.href.includes("var360customer")
        ? "Customer"
        : "Reseller";
    })();
  }

  get isAutoDomain(): boolean {
    const hostname = window.location.hostname;
    // const hostname = 'var360.milestonetech.com'
    const devTerms = [
      ".var.net",
      "localhost",
      ".dev-us.com",
      ".staging-us.com"
    ];
    return devTerms.every(dt => hostname.indexOf(dt) === -1);
  }

  get browser(): boolean {
    var ua = window.navigator.userAgent;
    var msie = ua.indexOf("MSIE ");
    var trident = ua.indexOf("Trident/");
    var browser: boolean;
    if (msie > 0 || trident > 0) {
      browser = true;
    } else {
      browser = false;
    }

    return browser;
  }

  async beforeCreate() {
    $("body").addClass("login");
  }

  async beforeDestroy() {
    $("body").removeClass("login");
  }

  async created() {
    if (this.$route.path.split("/")[1] == "passwordChange") {
      let self = this;
      self.passwordReset = true;
      self.userEmail = self.$route.params.email;
      self.loading = true;
      let dataObj = {
        controller: "Authentication",
        FunctionName: "passwordReset",
        userId: self.$route.params.id,
        token: self.$route.params.token,
        system: window.location.href.includes("var360customer")
          ? "Customer"
          : "Reseller"
      };
      let resultCategoryTrend = getRouteData(dataObj);
      await resultCategoryTrend.then(function (response) {
        if (response.data.status) {
          self.check = true;
        } else {
          self.check = false;
        }
        self.loading = false;
      });
    }
    if (this.isAutoDomain) {
      (this.$root as any).items.domain = window.location.hostname;
    }
    await this.checkExist();

    setTimeout(() => {
      $(".animate-first").addClass("show");
      setTimeout(() => {
        $(".animate-second").addClass("show");
      }, 100);
    }, 250);
  }

  mounted() {
    const self = this;

    // 2FAF
    $(document)
      .off("input", ".verified-code")
      .on("input", ".verified-code", function (e) {
        const $this = $(e.target);
        const value = $this.val();
        const index = parseInt($this.attr("data-index"));

        // Move to the next input if a character is entered
        if (value.length === 1) {
          const nextIndex = index + 1;
          const $nextInput = $(".verified-code[data-index=" + nextIndex + "]");
          if ($nextInput.length) {
            $nextInput.focus();
          }
        }
      });
    $(document)
      .off("paste", ".verified-code")
      .on("paste", ".verified-code", function (e) {
        e.preventDefault();
        const pasteData = (e.originalEvent || e).clipboardData.getData("text");
        const chars = pasteData.split("");

        // reset
        for (const item of self.code2FA) {
          item.val = "";
        }
        // Fill each input with a single character from the clipboard
        for (let i = 0; i < chars.length; i++) {
          const id = i + 1;
          const inList = self.code2FA.find(item => item.id == id);
          if (inList) {
            inList.val = chars[i];
          }
        }

        // Focus the last filled input
        const lastFilledIndex = Math.min(
          chars.length,
          $(".verified-code").length
        );
        $(".verified-code[data-index=" + lastFilledIndex + "]").focus();
      });
  }

  async checkExist() {
    this.loading = true;
    const response = await axios.post(dataURL + "?ReturnType=JSON", {
      controller: "domain",
      FunctionName: "checkExist",
      domain: window.location.hostname
    });

    if (response.data.status == 0 || response.data.status == -1) {
      this.checkExistShow = false;
      if (response.data.status == -1) {
        this.errorMessage = "The Account is Inactive";
        if (response.data.system == 1) {
          this.userRole = "Reseller";
        } else {
          this.userRole = "Customer";
        }
      } else if (response.data.status == 0) {
        this.errorMessage = "Domain Name not found";
      }
    } else {
      this.accountname = response.data.accountname;
      if (response.data.system == 1) {
        this.userRole = "Reseller";

        // endpoints/default endpoint
        this.endpoints = response.data.endpoints || [];
        this.selectedEndpointId = this.endpoints.length
          ? this.endpoints[0].ID
          : 0;
      } else {
        this.userRole = "Customer";
        if (response.data.defaultDomain == 1) {
          this.defaultDomain = true;
          // Comment out for checkside menu open issue
          this.changeStyle();
        }
        const PORTALINFO = response.data.PORTALINFO || {};
        this.primaryColor = PORTALINFO.PRIMARYCOLOR || "";
        this.portalLogo = PORTALINFO.CUSTOMERPORTALLOGO || "";
      }
      this.checkExistShow = true;
    }
    // For dev purpose
    if (window.location.hostname == "localhost") {
      if (window.location.href.includes("var360customer")) {
        this.userRole = "Customer";
        this.defaultDomain = true;
      }
    }

    this.loading = false;
  }
  changeStyle() {
    var cssFileName: string[] = [],
      cssDirectory = "";
    var scriptElement;

    const template = localStorage.getItem("VARtemplate") || "";

    if (this.userRole === "Customer") {
      if (template != "") {
        cssFileName = [
          "styles",
          "responsive",
          `${template}`,
          `${template}_customer`
        ];
      } else {
        cssFileName = ["styles", "responsive", "customer"];
      }
      scriptElement = document.createElement("script");
      scriptElement.src = "js/varcustomer.js";
      if (!$('script[src="js/varcustomer.js"]').length) {
        document.getElementsByTagName("head")[0].appendChild(scriptElement);
      }
    } else {
      cssFileName = ["styles", "responsive", "reseller", "reseller-responsive"];
      cssDirectory = "reseller";
      scriptElement = document.createElement("script");
      scriptElement.src = "js/reseller.js";
      if (!$('script[src="js/reseller.js"]').length) {
        document.getElementsByTagName("head")[0].appendChild(scriptElement);
      }
    }
    cssFileName.map(function (name) {
      var link = document.createElement("link");
      link.href = cssDirectory + "/css/" + name + ".css";
      link.type = "text/css";
      link.rel = "stylesheet";
      link.media = "screen,print";
      document.getElementsByTagName("head")[0].appendChild(link);
    });
  }
  rememberUser() {
    var doRemember = this.rememberMe ? "1" : "0";
    localStorage.setItem("rememberMe", doRemember);
  }
  async passwordValidate(e) {
    this.$validator.validateAll().then(result => {
      if (!$("#Password").val().length) {
        $(".errorMsg.password").text("The password field is required");
        result = false;
      } else {
        result = true;
      }
      if (!$("#confirmPassword").val().length) {
        $(".errorMsg.confirmPassword").text(
          "The confirm password field is required"
        );
        result = false;
      } else {
        result = true;
      }
      if ($(".passResetRequirements .validation.fail").length) {
        result = false;
      }
      if (result) {
        e.preventDefault();
        return this.submit();
      } else {
        e.preventDefault();
        e.stopPropagation();
      }
    });
  }
  validatePassword() {
    var pwd = $("#Password").val();
    var confirmPwd = $("#confirmPassword").val();

    if ($.trim(pwd).length) {
      $(".errorMsg.password").text("");

      if ($.trim(pwd) == $.trim(confirmPwd)) {
        $(".validationMatch").removeClass("fail").addClass("success");
      } else {
        if (!$(".validationMatch").hasClass("fail")) {
          $(".validationMatch").addClass("fail").removeClass("success");
        }
      }

      if ($.trim(pwd).match(/[A-Z]/)) {
        $(".validationUppercase").removeClass("fail").addClass("success");
      } else {
        if (!$(".validationUppercase").hasClass("fail")) {
          $(".validationUppercase").addClass("fail").removeClass("success");
        }
      }

      if ($.trim(pwd).length >= 8) {
        $(".validationLength").removeClass("fail").addClass("success");
      } else {
        if (!$(".validationLength").hasClass("fail")) {
          $(".validationLength").addClass("fail").removeClass("success");
        }
      }

      if ($.trim(pwd).match(/\d/)) {
        $(".validationNumber").removeClass("fail").addClass("success");
      } else {
        if (!$(".validationNumber").hasClass("fail")) {
          $(".validationNumber").addClass("fail").removeClass("success");
        }
      }
    } else {
      $(".passResetRequirements .validation")
        .removeClass("success")
        .addClass("fail");
    }
    if ($.trim(confirmPwd).length) $(".errorMsg.confirmPassword").text("");
  }
  submit() {
    let self = this;
    let dataObj = {
      controller: "Authentication",
      FunctionName: "passwordReset",
      userId: this.$route.params.id,
      update: 1,
      password: this.password,
      token: this.$route.params.token,
      system: window.location.href.includes("var360customer")
        ? "Customer"
        : "Reseller"
    };
    let resultCategoryTrend = getRouteData(dataObj);
    resultCategoryTrend.then(function (response) {
      if (response.data.msg) notifier.success(response.data.msg);
    });
    self.$router.push({ name: "Dashboard" });
  }

  async doLogin(send2FACode = false) {
    const valid = await this.$validator.validateAll();
    if (!valid) {
      return;
    }

    const win = window as any;

    if ($("#awn-toast-container").length) {
      $("#awn-toast-container").remove();
    }

    // check if has selected endpoint
    const selectedEndpoint = this.selectedEndpointId
      ? this.endpoints.find(item => item.ID == this.selectedEndpointId)
      : {};
    const epURL = selectedEndpoint ? selectedEndpoint.EPURL || "" : "";
    if (epURL) {
      dataURL = epURL;
    }

    const dataObj = { controller: "authentication", FunctionName: "login" };
    dataObj["UserEmail"] = this.$root.items.userName;
    dataObj["UserPass"] = this.$root.items.pwd;
    dataObj["Domain"] = this.$root.items.domain;
    dataObj["DomainName"] = (function () {
      if (
        window.location.href.includes("localhost") &&
        !window.location.href.includes("customer")
      ) {
        return localStorage.getItem("userRole") || "Reseller";
      }

      return window.location.href.includes("customer")
        ? "Customer"
        : "Reseller";
    })();
    dataObj["uuid2FA"] = win.getCookie("uuid2FA") || "";

    // validate if force verify 2FA code
    if (send2FACode) {
      const code2FAFull = this.code2FA.map(item => item.val).join("");
      if (code2FAFull.length != 6) {
        return;
      } else {
        dataObj["verify2FAF"] = 1;
        dataObj["userToken"] = this.userToken2FAF;
        dataObj["verifiedCodes"] = code2FAFull;
      }
    }

    const response = await axios.post(dataURL + "?ReturnType=JSON", dataObj);
    try {
      if (response.data.STATUS && !response.data.ERROR) {
        const enable2FAF = response.data.enable2FAF || 0;
        if (enable2FAF) {
          this.userToken2FAF = response.data.userToken || "";
          this.form2FAFVisible = true;
          return;
        }

        this.verify2FAFFail = false;
        var expires = 3600;
        var date = new Date();
        var role =
          parseInt(response.data.LOGINDETAILS[0].SYSTEM) == 1
            ? "Reseller"
            : "Customer";
        const schedule = Math.round(
          date.setSeconds(date.getSeconds() + expires) / 1000
        );
        var winWidth = $(window).width();
        var winHeight = $(window).height();
        var maxRows = win.checkScreenSize(true);
        var dataSessionForStorage = {
          winWidth: winWidth,
          winHeight: winHeight,
          sessionID: response.data.SESSION,
          expireIn: schedule,
          userId: response.data.LOGINDETAILS[0].USERID,
          SYSTEM: response.data.LOGINDETAILS[0].SYSTEM,
          username: response.data.LOGINDETAILS[0].USERNAME || "",
          userFname: response.data.LOGINDETAILS[0].UFNAME || "",
          userLname: response.data.LOGINDETAILS[0].ULNAME || "",
          userRole: role,
          currentUser: JSON.stringify(response.data.LOGINDETAILS),
          IncognitoMode: response.data.LOGINDETAILS[0].INCOGNITOMODE,
          quoteStatusFilter: "0",
          accountName: response.data.LOGINDETAILS[0].ACCOUNTNAME || "",
          maxRows: maxRows,
          accountId: response.data.LOGINDETAILS[0].ACCOUNTID || ""
        };
        var PORTALINFO = response.data.PORTALINFO || {};
        if (role == "Reseller") {
          PORTALINFO = {};
        }
        localStorage.setItem("PORTALINFO", JSON.stringify(PORTALINFO));

        var EMPLOYEEPLAN = response.data.EMPLOYEEPLAN || {};
        localStorage.setItem("EMPLOYEEPLAN", JSON.stringify(EMPLOYEEPLAN));

        var ALERTCONFIG = response.data.ALERTCONFIG || {};
        localStorage.setItem("ALERTCONFIG", JSON.stringify(ALERTCONFIG));

        var LOGINDETAILS = response.data.LOGINDETAILS[0] || {};
        //session on one page
        localStorage.setItem("VARtemplate", response.data.VARtemplate);
        sessionStorage.setItem("winWidth", winWidth);
        sessionStorage.setItem("winHeight", winHeight);
        sessionStorage.setItem("sessionID", response.data.SESSION);
        sessionStorage.setItem("expireIn", `${schedule}`);
        sessionStorage.setItem("userId", LOGINDETAILS.USERID);
        sessionStorage.setItem("SYSTEM", LOGINDETAILS.SYSTEM || "");
        sessionStorage.setItem("userName", LOGINDETAILS.USERNAME || "");
        sessionStorage.setItem("userFname", LOGINDETAILS.UFNAME || "");
        sessionStorage.setItem("userLname", LOGINDETAILS.ULNAME || "");
        sessionStorage.setItem("userRole", role);
        sessionStorage.setItem(
          "currentUser",
          JSON.stringify(response.data.LOGINDETAILS)
        );
        sessionStorage.setItem("domain", LOGINDETAILS.DOMAIN);
        sessionStorage.setItem("IncognitoMode", LOGINDETAILS.INCOGNITOMODE);
        sessionStorage.setItem("quoteStatusFilter", "0");
        sessionStorage.setItem("accountName", LOGINDETAILS.ACCOUNTNAME || "");
        sessionStorage.setItem("isInitialLoad", "true");
        sessionStorage.setItem("maxRows", maxRows);
        sessionStorage.setItem("accountId", LOGINDETAILS.ACCOUNTID || "");
        if(selectedEndpoint) {
          localStorage.setItem("selectedEndpoint", JSON.stringify(selectedEndpoint));
        }

        var pageArchieve = {
          Quotes: 1,
          Orders: 1,
          Invoices: 1,
          Order: 1,
          paychecks: 1,
          ledgers: 1,
          users: 1,
          customers: 1,
          Supports: 1,
          securityGroups: 1,
          HPAgentDetails: 1,
          indirectorders: 1,
          customerPortals: 1,
          HPAgent: 1,
          Cashflow: 1
        };
        sessionStorage.setItem("pageDetails", JSON.stringify(pageArchieve));
        var pageFilters = {
          Quotes: {},
          Orders: {},
          Invoices: {},
          Opportunities: {}
        };
        sessionStorage.setItem("filterDetails", JSON.stringify(pageFilters));
        //storage session local when open multi tab
        win.setCookie(
          "VAR360_SESSION_LOCAL",
          JSON.stringify(dataSessionForStorage)
        );
        localStorage.setItem("rememberMesession", "");
        if (localStorage.rememberMe == 1) {
          localStorage.setItem("rememberMesession", response.data.SESSION);
          localStorage.setItem(
            "lastSession",
            JSON.stringify(dataSessionForStorage)
          );
        }
        localStorage.setItem("userRole", role);
        //set container
        // container.items = response.data;
        this.$root.items = response.data;

        if (dataObj["verify2FAF"] == 1) {
          // create 2FA UUID cookie
          const uuid2FA = response.data.UUID2FA || "";
          win.setCookie("uuid2FA", uuid2FA, 30 * 365);
        }

        //redirect to Dashboard
        // document.getElementById('topLogo').classList.add("d-none");
        // document.getElementById('leftSection').classList.add("d-none");
        // document.getElementById('loginForm').classList.add("d-none");
        $("#topLogo, #leftSection, #loginForm").addClass("d-none");
        $(".frm-2faf").hide();

        setTimeout(function () {
          var redirectLoading = document.getElementById("redirectLoading");
          if (redirectLoading) {
            redirectLoading.classList.remove("d-none");
          }
        }, 100);

        if ($("#urlParamsExist").val() == 1) {
          var path = $("#urlParams").val();
          // document.getElementById("urlParamsExist").value = 0;
          // document.getElementById("urlParams").value = '/';
          $("#urlParamsExist").val(0);
          $("#urlParams").val("/");
          this.$router.push(path);
        } else {
          this.$router.push({ name: "Dashboard" });
        }
        // update Style
        $(".userType").remove();
        var userRole = role;
        var cssFileName: string[] = [];
        var cssDirectory = "";
        var scriptElement = $(".userJS");
        // console.log(userRole);
        if (userRole === "Reseller") {
          if (localStorage.getItem("VARtemplate") != "") {
            cssFileName = [
              "styles",
              "responsive",
              "reseller",
              "reseller-responsive",
              `${localStorage.getItem("VARtemplate")}`
            ];
          } else {
            cssFileName = [
              "styles",
              "responsive",
              "reseller",
              "reseller-responsive"
            ];
          }
          var scriptElement: any = document.createElement("script");
          scriptElement.className = "userJS";
          scriptElement.src = "js/reseller.js";
          // document.getElementsByTagName("head")[0].appendChild(scriptElement);
        } else {
          if (localStorage.getItem("VARtemplate") != "") {
            cssFileName = [
              "styles",
              "responsive",
              "reseller",
              "reseller-responsive",
              `${localStorage.getItem("VARtemplate")}`,
              `${localStorage.getItem("VARtemplate")}_customer`
            ];
          } else {
            cssFileName = [
              "styles",
              "responsive",
              "reseller",
              "reseller-responsive"
            ];
          }
          var scriptElement: any = document.createElement("script");
          scriptElement.className = "userJS";
          scriptElement.src = "js/varcustomer.js";
          // document.getElementsByTagName("head")[0].appendChild(scriptElement);
        }
        cssFileName.map(function (name) {
          var linkHref = "/css/" + name + ".css";

          if ($(`link[href='${linkHref}'`).length === 0) {
            var link = document.createElement("link");
            link.href = linkHref;
            link.type = "text/css";
            link.className = name;
            link.rel = "stylesheet";
            link.media = "screen,print";
            document.getElementsByTagName("head")[0].appendChild(link);
          } else {
            var removeLink = $(`link[href='${linkHref}'`);
            removeLink.remove();

            var link = document.createElement("link");
            link.href = linkHref;
            link.type = "text/css";
            link.className = name;
            link.rel = "stylesheet";
            link.media = "screen,print";
            document.getElementsByTagName("head")[0].appendChild(link);
          }
        });

        //check Screen size
        var checkPage = setTimeout(function () {
          win.checkScreenSize();
          clearTimeout(checkPage);
        }, 200);
      } else if (!response.data.STATUS) {
        // console.log(dataObj);
        // console.log(response.data.ERROR);
        if (response.data.ERROR) {
          $("body").append(
            '<div id="awn-toast-container" class="awn-top"><div id="awn-toast-1576219167433" class="awn-toast awn-toast-alert" style="animation-duration: 0.3s;"><div class="awn-toast-progress-bar"></div><b class="awn-toast-label">' +
              response.data.ERROR +
              '</b><div class="awn-toast-content"></div><span class="awn-toast-icon"><i class="fa fa-fw fa-warning"></i></span></div></div>'
          );
          $("#awn-toast-container").delay(1000).fadeOut();
          setTimeout(function () {
            $("#awn-toast-container").remove();
          }, 1000);
        } else {
          const errorCode = response.data.ERRORCODE || "";
          const statusMessage = response.data.STATUSMESSAGE || "";
          if (
            ["out_date", "not_found"].includes(errorCode) &&
            dataObj["verify2FAF"] == 1
          ) {
            // verify 2fa code failed
            this.verify2FAFFail = true;
          }
        }
      }
    } catch (err) {
      console.log(err);
    }
  }

  get valid2FACode() {
    let ret = this.code2FA.every(item => item.val != "");
    return ret;
  }
}
