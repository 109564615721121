import { render, staticRenderFns } from "./PayrollAccount.vue?vue&type=template&id=efb25d82&scoped=true"
import script from "./PayrollAccount.vue?vue&type=script&lang=tsx"
export * from "./PayrollAccount.vue?vue&type=script&lang=tsx"
import style0 from "./PayrollAccount.vue?vue&type=style&index=0&id=efb25d82&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "efb25d82",
  null
  
)

export default component.exports