





























































































































































































































































































































































































































































































































































































































































































































































































































































































































































import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop } from "vue-property-decorator";
import QuoteCreate from "../pages/QuoteCreate.vue";
import ProductCatTooltip from "../components/ProductCatTooltip.vue";
import MarginViewTooltip from "../components/MarginViewTooltip.vue";
import directives from "../helpers/directives";
import DistyPAOptionsTooltip from "../components/DistyPAOptionsTooltip.vue";
import ConfirmYesNoTooltip from "../components/ConfirmYesNoTooltip.vue";
import { dollarFormat, getEUAddress, isPriceExceeded, isPriceListExceeded } from "@/helpers/ApiHelper";
import ContractPriceTooltip from "../components/ContractPriceTooltip.vue";
import BuildHeaderTooltip from "@/components/BuildHeaderTooltip.vue";

@Component({
  inheritAttrs: false,
  components: {
    QuoteCreate,
    ProductCatTooltip,
    MarginViewTooltip,
    DistyPAOptionsTooltip,
    ConfirmYesNoTooltip,
    ContractPriceTooltip,
    BuildHeaderTooltip
  },
  directives,
  methods: {
    dollarFormat,
    getEUAddress,
    isPriceExceeded,
    isPriceListExceeded
  }
})
export default class CreateSimplified extends TSXComponent<void> {
  $parent: any;
  @Prop({ required: false, default: 0 })
  items!: any;
  DistyPAOptionsVisible = 0;
  contractPriceTooltipIndex = -1;
  buildHeaderTooltipIndex = -1;

  data() {
    // $(".estPercent").removeClass("displayNone");

    return {
      itemNames: this.$parent.itemNames
    };
  }
  async childValidation() {
    const valid = await this.$validator.validateAll();
    return valid;
  }

  selectDisty(item, distyID, index) {
    const distyPrice = item.dynamicInfo[`Disty_${distyID}_Price`] || 0;
    if(distyPrice == 0) {
      return;
    }

    item.dynamicInfo.selectedDistyID = distyID;
    item.price = distyPrice;
    this.$parent.calcWithChangeCostPrice(index);
    this.$forceUpdate();
  }

  showContractTooltip(index) {
    this.contractPriceTooltipIndex = index;
  }

  hideContractTooltip() {
    this.contractPriceTooltipIndex = -1;
  }

  updateContractPrice(data, item, index) {
    item.customerPrice = data.contractPrice;
    this.contractPriceTooltipIndex = -1;
    this.$parent.marginCalc1(index);
  }

  saveBuildHeader(data, item) {
    item.buildHeader = data.buildHeader || '';
    this.buildHeaderTooltipIndex = -1;
  }

  checkConfigPrice(item) {
    let totalConfigPrice = 0;
    if (item.customerPrice != 0) {
      for(let i in this.items) {
        if(this.items[i].includedparent == item.quoteLiId && this.items[i].ISCONFIG) {
          totalConfigPrice += this.items[i].customerPrice || 0;
        }
      }
    }
    return totalConfigPrice != 0 ? false : true;
  }
}
