









































































import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Emit, Model, Watch } from "vue-property-decorator";
import { ApiHelper } from "@/helpers/all";

@Component({
  inheritAttrs: true,
  components: {}
})

export default class PayrollAccountComponent extends TSXComponent<void> {

  @Prop({ required: false, default: 0 })
  batchID!: number;

  @Prop({ required: false, default: "" })
  compUUID!: string;

  @Prop({ required: false, default: "" })
  viewType!: string;

  accountData: any = [];
  totalAccount: number = 0;
  loading: boolean = false;
  
  clickOutside() {
    this.$emit("close");
  }

  async created() {
    if (this.batchID) {
      this.loading = true;
      const response = await ApiHelper.callApi('post', {
        controller: "Finances",
        FunctionName: "payrollAccountList",
        batchID: this.batchID
      });

      if (response.STATUS === 1) {
        this.accountData = response.PAYROLLACCOUNT;
        this.totalAccount = response.TOTALPAYROLLACCOUNT;
      }
      this.loading = false;
    } else if (this.compUUID.length) {
      this.loading = true;
      const response = await ApiHelper.callApi('post', {
        controller: "Finances",
        FunctionName: "CompAccountList",
        compUUID: this.compUUID
      });

      if (response.STATUS === 1) {
        this.accountData = response.EMPCOMPACCOUNT;
        this.totalAccount = response.TOTALEMPCOMPACCOUNT;
      }
      this.loading = false;
    }
  }
}
