





























































































































































import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Emit, Model, Watch } from "vue-property-decorator";
import { ApiHelper } from "@/helpers/all";
import DropdownControl from "@/components/DropdownControl.vue";
import { notifier } from "@/models/common";

@Component({
  inheritAttrs: true,
  components: {
    DropdownControl
  }
})

export default class TargetBusinessLineTooltip extends TSXComponent<void> {

  @Prop({ required: false, default: 0 })
  aID!: number;

  @Prop({ required: false, default: new Date().getFullYear() })
  targetYear!: any;

  @Prop({ required: false, default: [] })
  targetBusinessLineOptions!: any;

  loading: boolean = false;
  targets: any[] = [];
  editRowIndex: number = -1;

  created() {
    this.fetchData();
  }

  async fetchData() {
    this.loading = true;
    const response = await ApiHelper.callApi("post", {
      controller        : "Customers",
      FunctionName      : "ProfitTargetList",
      aID               : this.aID,
      targetYear        : this.targetYear,
      action            : "getActiveTarget"
    });

    if (response.STATUS === 1) {
      this.targets = response.PROFIT_TARGETS || [];
    }
    this.loading = false;
  }

  clickOutside() {
    this.$emit("close");
  }

  edit(item, index) {
    item["ISTARGETLISTPAGE"] = true;
    item["ISEDITTARGET"] = true;
    this.editRowIndex = index;
    this.$forceUpdate();
  }

  addTarget() {
    let item = {};
    item["AID"] = this.aID;
    item["TARGET_YEAR"] = this.targetYear;
    item["ISTARGETLISTPAGE"] = true;
    this.$emit("edit", {
      item
    });
  }

  updateBusinessLine(id, item) {
    item.BUSINESSLINEID = id;
  }

  async saveTargetProfit(item) {
    const response = await ApiHelper.callApi("post", {
      controller: "Customers",
      FunctionName: "UpdateProfitTargets",
      aID: this.aID,
      target_year: this.targetYear,
      target_profit: item.TARGET_PROFIT,
      action: "updateProfitTarget",
      businessLineId: item.BUSINESSLINEID,
      targetUUID: item.TARGETUUID,
      isUpdateTargetBL: true
    });

    if (response.STATUS === 1) {
      notifier.success(response.STATUSMESSAGE);
      this.targets = response.PROFIT_TARGETS || [];
      this.editRowIndex = -1;
      this.$emit("updateTarget");
    } else {
      notifier.alert(response.STATUSMESSAGE);
    }
  }

}
