





































































































































































































import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Emit, Watch } from "vue-property-decorator";
import { AcctsListResp } from "../models/AcctsListResp";

interface Props {
  options: AcctsListResp.AccountsSelect[];
  selectedIDs: number[];
  hideSelectAll: boolean;
  multiSelect: boolean;
}

interface Events {}

declare const $: any;

@Component({
  inheritAttrs: false,
  components: {}
})
export default class DropdownControl extends TSXComponent<Props, Events> {
  @Prop({ required: true })
  options!: AcctsListResp.AccountsSelect[];

  @Prop({ required: true })
  selectedIDs!: number[];

  @Prop({ required: false, default: "" })
  label!: string;

  @Prop({ required: false, default: "" })
  labelClass!: string;

  @Prop({ required: false, default: false })
  hideSelectAll!: boolean;

  @Prop({ required: false, default: true })
  multiSelect!: boolean;

  @Prop({ required: false, default: false })
  hideSearch?: boolean;

  @Prop({ required: false, default: false })
  hideCheckMark?: boolean;

  @Prop({ required: false, default: "" })
  placeholderText?: string;

  @Prop({ required: false, default: "" })
  customWidth?: string;

  @Prop({ required: false, default: "" })
  selectedState?: string;

  @Prop({ required: false, default: -1 })
  indexVal?: number;

  @Prop({ required: false, default: false })
  enabletab?: boolean;

  @Prop({ required: false, default: false })
  focusSelect?: boolean;

  @Prop({ required: false, default: '' })
  errorClass?: string;

  @Prop({ required: false, default: false })
  showContent?: boolean;

  @Prop({ required: false, default: true })
  showNoRecordsText?: boolean;

  @Prop({ required: false, default: false })
  addNew?: boolean;

  @Prop({ required: false, default: 'Add' })
  addNewText?: string;

  @Prop({ required: false, default: 0 })
  aID?: number;

  @Prop({ required: false, default: false })
  isEndUser?: boolean;

  @Prop({ required: false, default: false })
  useEUData?: boolean;

  @Prop({ required: false, default: 0 })
  highlightedId!: number;

  @Prop({ required: false, default: false })
  disabled!: any;

  @Prop({ required: false, default: false })
  showTitleHover!: boolean;

  @Prop({ required: false, default: "Search" })
  placeholderSearch!: string;

  @Prop({ required: false, default: false })
  requiredField!: boolean;

	@Prop({ required: false, default: "" })
  selectedText!: string;

	@Prop({ required: false, default: false })
	customItemTextClick!: boolean;

	@Prop({ required: false, default: "text" })
  optionType!: string;

	@Prop({ required: false, default: "No Records Found" })
  notFoundMsg!: string;

  $parent: any;
  dropdownVisible = false;
  isfocused = false;
  search = "";
  showOnlySelected: boolean = false;
  stateSelector!: StateSelector;
	clickedCheckbox = false;

	mounted() {
    this.stateSelector = new StateSelector(this.options, this);
  }

	beforeDestroy() {
    this.removeEventListeners();
  }

  get filteredOptions() {
    let options = this.options;
    let lastOption: any = options[options.length - 1];
    let lastOptionOneTime: any = null;

    if (this.search) {
      options = options.filter(
        option => `${option.TEXT}`.toLowerCase().indexOf(this.search.toLowerCase()) !== -1
      );

      if (options.length == 0 && lastOption && lastOption.ID === 'onetime') {
        lastOptionOneTime = [];
        lastOptionOneTime.push(lastOption);
      }
    }

    if (lastOptionOneTime != null) {
      options = lastOptionOneTime;
    } else if (this.showOnlySelected) {
      options = options.filter(
        option => this.selectedIDs.indexOf(option.ID) !== -1
      );
    }

    return options;
  }

  topDropdown = true;
  toggleDropdown() {
    const tooltipHeight = $(this.$refs.dropdownContainer).offset().top - $(window).scrollTop();
    const windowHeight = $(window).height();
    const bottom = windowHeight - tooltipHeight;

    if (bottom <= tooltipHeight) {
      this.topDropdown = true;
    }
    else {
      this.topDropdown = false;
    }
    if (!this.isfocused && this.disabled == 0) {
      this.dropdownVisible = !this.dropdownVisible;
      if (this.dropdownVisible) {
        this.isfocused = false;
      }
    } else {
      this.isfocused = false;
    }
  }

  toggleDropdownOpen() {
    if (!this.dropdownVisible) {
      this.dropdownVisible = true;
      this.isfocused = true;
    }
  }

  focusSelection() {
    this.stateSelector = new StateSelector(this.options, this);
    document.addEventListener('keydown', this.stateSelector.handleKeyDown.bind(this.stateSelector));
  }

  clickOutside() {
		// specify if should fire an event
		const fireEvent = this.dropdownVisible && this.clickedCheckbox ? true : false;
		// hide tooltip
    this.dropdownVisible = false;
		
		if(fireEvent) {
			this.clickedCheckbox = false; // reset
			this.$emit("clickOutside");
		}
  }

  isChecked(id: any): boolean {
    let checked;
    let checkedOneTimeAddr;

    if ($.isNumeric(this.selectedIDs)) {
      checked = this.selectedIDs === id;
      checkedOneTimeAddr = this.selectedIDs === id;
    } else {
      checked = !!this.selectedIDs.find(val => val === id);
      checkedOneTimeAddr = this.selectedIDs.find(val => val === id);
    }
    if (checkedOneTimeAddr === 0) {
      return true;
    }
    return checked;
  }

  selectAll() {
    this.$emit("selectAll");
  }

  reset() {
    this.$emit("reset");
  }

  getSelectedOption() {
    var ret: (string | undefined) = "";
    if (this.selectedIDs.length) {
      let tmp: any = this.options.filter(
        (item: any) => item.ID == this.selectedIDs[0]
      );
      if (tmp.length) {
				ret = this.isEndUser && !(this.useEUData || false)  ? tmp[0].NAME : tmp[0].TEXT;
      }
    }
    if (this.indexVal != -1) {
      let tmp = this.options.filter(
        (item: any) => item.TEXT == this.selectedState
      );
      if (tmp.length) {
        ret = tmp[0].TEXT;
      } else {
        ret = this.selectedState;
      }
    }
    return ret;
  }

  checkToHide() {
    if (!this.multiSelect) {
      this.dropdownVisible = false;
    }
  }

  addNewOption() {
    this.$emit('check', 'onetime');
    this.$emit("addNewItem", this.search);
    this.search = "";
  }

  resetSearch() {
    this.search = "";
  }

  removeEventListeners() {
    document.removeEventListener('keydown', this.stateSelector.handleKeyDown.bind(this.stateSelector));
    document.removeEventListener('blur', this.stateSelector.updateState.bind(this.stateSelector));
  }

	itemTextClick(e, id) {
		if(!this.customItemTextClick) {
			return;
		}

		this.$emit("itemTextClick", id, e);
	}

	itemCheckboxClick() {
		this.clickedCheckbox = true;
	}
}

class StateSelector {
  options: any[];
  selectedIndex: number;
  selectedState: string | null;
  isFocused: boolean;
  keySequence: string;
  keyTimeout: any;
  parent: any;

  constructor(options: any[], parent: any) {
    this.options = options;
    this.selectedIndex = -1;
    this.selectedState = null;
    this.isFocused = false;
    this.keySequence = '';
    this.keyTimeout = null;
    this.parent = parent;
  }

  handleKeyDown(event: KeyboardEvent) {
	if($(".stateInput:focus").length === 1){
		this.isFocused = true;
		clearTimeout(this.keyTimeout);
	
		switch (event.key) {
		  case 'ArrowUp':
		  case '8':
			event.preventDefault();
			this.decreaseFocus();
			break;
		  case 'ArrowDown':
		  case '2':
			event.preventDefault();
			this.increaseFocus();
			break;
		  case 'Tab':
			this.updateState();
			this.removeEventListeners();
			break;
		  default:
			if (event.key.length === 1 && event.key.match(/[a-z]/i)) {
			  this.keySequence += event.key.toUpperCase();
			  this.selectFirstStateStartingWith(this.keySequence);
			} else {
			  this.keySequence = '';
			}
			break;
		}
	
		this.keyTimeout = setTimeout(() => {
		  this.keySequence = '';
		}, 1000);
	}
  }

  selectFirstStateStartingWith(letterSequence: string) {
    const index = this.options.findIndex(option => option.TEXT.startsWith(letterSequence));
    if (index !== -1) {
      this.selectedIndex = index;
      this.selectedState = this.options[index].TEXT;
      this.updateState();
    }
  }

  decreaseFocus() {
    if (this.selectedIndex > 0) {
      this.selectedIndex--;
      this.selectedState = this.options[this.selectedIndex].TEXT;
      this.updateState();
    }
  }

  increaseFocus() {
    if (this.selectedIndex < this.options.length - 1) {
      this.selectedIndex++;
      this.selectedState = this.options[this.selectedIndex].TEXT;
      this.updateState();
    }
  }

  updateState() {
	this.parent.$emit('check', this.selectedState, this.parent.indexVal);
  }

  removeEventListeners() {
    document.removeEventListener('keydown', this.handleKeyDown.bind(this));
    document.removeEventListener('blur', this.updateState.bind(this));
  }
}
