





































































































































































































































































































































































import axios from "axios";
import { VNode } from "vue";
import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop } from "vue-property-decorator";
import { ErrorBag } from "vee-validate";
import { roundCents, notifier, roundCentsWithDecimal } from "../models/common";
import SKUAutoSuggestInput from "./SKUAutoSuggestInput.vue";
import { Summary } from "./OrderFormSummary.vue";
import { Option } from "./AutoSuggestInput.vue";
import { QuoteLineItemList } from "../models/QuoteLineItemList";
import OrderFormLineItemListRow, {
  ItemName
} from "./OrderFormLineItemListRow.vue";
import MarginViewTooltip from "../components/MarginViewTooltip.vue";
import OrderTaxRateTooltip from "../components/OrderTaxRateTooltip.vue";
import { ApiHelper } from "@/helpers/all";
import { dollarFormat, getEUAddress } from "@/helpers/ApiHelper";
import SubOrderQuoteEndUser from "@/components/SubOrderQuoteEndUser.vue";
import FilterSearch from "@/components/List/FilterSearch.vue";
import { v4 as uuidv4 } from "uuid";

declare const htmlParse: Function;
declare const getRouteData: Function;
declare const dataURL: string;

type ItemNames = Record<string, ItemName>;

// const findParent = (
//   indexName: string,
//   itemNames: ItemNames
// ): ItemName | null => {
//   const idx = parseInt(indexName.split("_")[1]);
//   // find parent
//   for (let i = idx - 1; i >= 1; i--) {
//     const each = itemNames[`productName_${i}`];
//     if (!each) {
//       continue;
//     }
//     if (each.included === 0) {
//       return each;
//     }
//   }

//   return null;
// };

const findParent = (index: string, itemNames: any) => {
  const currentItem = itemNames[index];
  if (!currentItem || !(currentItem.included || 0)) return null;

  // find parent for an included item
  let parent: any = null;
  const keys = Object.keys(itemNames);
  for (let i = 0; i <= keys.length - 1; i++) {
    const item = itemNames[keys[i]];
    if (!(item.included || 0)) {
      parent = item;
    }
    if (index == keys[i]) {
      break;
    }
  }

  return parent;
};

const findOtherIncludedItems = (
  indexName: string,
  itemNames: ItemNames
): ItemName[] => {
  const idx = parseInt(indexName.split("_")[1]);
  const items: any[] = [];
  // find parent
  for (let i = idx - 1; i >= 1; i--) {
    const each = itemNames[`productName_${i}`];
    if (!each) {
      continue;
    }
    if (each.included === 0) {
      break;
    }

    items.push(each);
  }

  return items;
};

interface Props {}

interface Events {
  onSummaryChange: Summary;
}

declare const $: any;

@Component({
  inheritAttrs: false,
  components: {
    FilterSearch,
    SKUAutoSuggestInput,
    OrderFormLineItemListRow,
    MarginViewTooltip,
    OrderTaxRateTooltip,
    SubOrderQuoteEndUser
  },
  methods: {
    getEUAddress
  }
})
export default class OrderFormLineItemList extends TSXComponent<Props, Events> {
  @Prop({ required: false, default: false })
  isMultiEndUsers!: boolean;

  @Prop({ required: false, default: {} })
  configCategory!: any;

  $parent: any;
  $route: any;
  $decimalsView: any;
  $decimals: any;
  itemNames: ItemNames;
  orderList: any;
  length = 3;
  autoName = 1;
  autoCost = 0;
  marginInput = "rate"; //default
  generateForUnverify = false;
  MarginViewTooltipVisible = false;
  marginView = "margin_per"; //default
  taxRateVisible = false;
  feeVisibleIndex = "";
  selectedIndexForTax = "";
  percent = 0;
  checkingSKU = false;
  euDataVisible = false;
  hideApiCall = false;
  selectedSubOrder: any = {};
  pageNumber = 1;
  subsetPerPage = 10;
  loadingMore = false;
  filters = {
    name: ""
  };
  detailsView = "sub-orders";
  detailsViewData = [];
  selectedGroupItems = {
    indexes: []
  };

  $refs!: {
    rows: OrderFormLineItemListRow[];
    detailsDropdown: any;
  };
  checkAllTax: boolean = false;

  constructor() {
    super();

    const itemNames: ItemNames = {};

    for (let i = 1; i <= this.length; i++) {
      itemNames["productName_" + i] = {
        sku: "",
        name: "",
        price: 0,
        customerPrice: 0,
        margin: 0,
        marginRate: 0,
        categoryType: 0,
        total: 0,
        quantity: 0,
        tax: 0,
        taxRate: 0,
        shipping: 0,
        included: 0,
        priceWarn: false,
        isFee: false,
        noTax: false,
        dynamicInfo: {},
        statusid: 0,
        contractPrice: undefined,
        contractPriceDate: undefined,
        orgPrice: 0,
        orgCustomerPrice: 0,
        disable: false,
        isContractFee: false,
        includedparent: 0,
        baseProductId: 0,
        includeItemCount: 0,
        quoteLiId: 0,
        quoteParentLiId: 0,
        buildHeader: "",
        selectedSource: [],
        sourceId: 0
      };
    }

    this.itemNames = itemNames;
  }

  catList = [];

  created() {
    this.generateForUnverify = this.$parent.generateForUnverify;
    // get category list
    ApiHelper.callApi("post", {
      controller: "Helpers",
      FunctionName: "Category"
    }).then(response => {
      if (response.STATUS == 1) {
        this.catList = response.CATEGORY || [];
      }
    });
  }

  mounted() {
    // handle scroll
    if (["OrderEdit", "OrderCreate"].includes(this.$route.name)) {
      window.addEventListener("scroll", this.handleScroll);
    }
  }

  handleScroll(e) {
    if (!["OrderEdit", "OrderCreate"].includes(this.$route.name)) {
      return;
    }

    let documentHeight = document.body.scrollHeight;
    let currentScroll = window.scrollY + window.innerHeight;
    let showingCnt = $(".accordion-item").length;
    if (showingCnt < this.pageNumber * this.subsetPerPage) {
      return;
    }

    if (currentScroll + 300 > documentHeight) {
      if (this.pageNumber * this.subsetPerPage < this.subsetIds.length) {
        this.loadingMore = true;
        setTimeout(() => {
          this.pageNumber += 1;
          this.$nextTick().then(() => {
            this.loadingMore = false;
          });
        }, 50);
      }
    }
  }

  findIdx(indexName: string): number {
    return Object.keys(this.itemNames).indexOf(indexName);
  }

  private updateSummary(type?: number): Summary {
    let itemTotal = 0;
    let totalCost = 0;
    let totalTax = 0;
    let totalShipping = this.$parent.summary.totalShipping;
    let includeVarCost = this.$parent.summary.includeVarCost;
    let profit: any = 0;
    let estPercent = this.$parent.summary.estPercent;
    let totalTaxable = 0;
    let totalFee = 0;

    Object.keys(this.itemNames).forEach(key => {
      const item = this.itemNames[key];

      if (typeof this.itemNames[key] !== "undefined") {
        // this.itemNames[key].customerPrice = this.itemNames[key].customerPrice
        //   ? this.itemNames[key].customerPrice
        //   : this.itemNames[key].total;

        // if (item.included) {
        //   item.quantity = 0;
        //   // item.taxRate = 0;
        //   item.tax = 0;
        // }
        let tmpProfit = (item.customerPrice - item.price) * item.quantity;
        if (isNaN(tmpProfit)) {
          tmpProfit = 0;
        }

        // var noTax = typeof item.noTax != "undefined" && item.noTax ? true : false;
        // if (noTax == false && !item.included) {
        //   totalTax += item.tax;
        // }
        item.total =
          parseFloat(
            item.customerPrice.toFixed(this.$parent.expandDecimalPrecision)
          ) * item.quantity;
        // item.total = item.customerPrice * item.quantity;
        const isFee = item.isFee || false;
        if (item.statusid != 1 && item.statusid != 2) {
          // if(!isFee) {
          //   profit += tmpProfit;
          // }
          profit += tmpProfit;
          if (!item.included) {
            itemTotal += this.getBaseTotal(item, key).total;
          } else if (!(item.included && item.ISCONFIG)) {
            // grouped item
            itemTotal += item.total;
          }
          totalCost += item.price * item.quantity;

          /* if (item.taxRate != 0 && !(item.included && item.ISCONFIG)) {
            if (!item.included) {
              totalTaxable += this.getBaseTotal(item, key).total;
            } else {
              // grouped item
              totalTaxable += item.total;
            }

            // include configs of this base product
            // const configItems = this.getIncludedItems(item, key).filter(
            //   t => t.ISCONFIG || 0
            // );
            // for (const t of configItems) {
            //   totalTaxable += t.total || 0;
            // }
          } */
          if (item.taxRate != 0) {
            totalTaxable += item.total;
          }
        }
      }
    });

    // if (this.orderList != undefined && this.orderList != "" && type == 2) {
    //   //type convert from quote
    //   totalShipping = this.orderList.TOTALSHIPPING;
    //   totalTax = parseFloat(this.orderList.TOTALTAX);
    // }

    //we no change tax if user use a custom tax, and do change something for price, customer price, or quantity
    //if (this.usedCustomTax() && typeof this.$parent.comeFrom != "undefined" && $.inArray(this.$parent.comeFrom, ["cost_per", "price_per", "qty"]) != -1) {
    if (this.usedCustomTax()) {
      totalTax = parseFloat(this.$parent.summary.totalTax);
    } else {
      // calculate total tax based on a general tax rate of this customer
      totalTax = parseFloat(
        roundCents((totalTaxable * this.$parent.currentTaxRate) / 100).toFixed(
          2
        )
      );
    }

    //if user inputted an EST % before, we canculate profit based on subTotal*EST%
    if (!this.$parent.isIndirect() && estPercent > 0) {
      profit = (itemTotal * estPercent) / 100;
    }
    var format = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 2
    });

    profit = format.format(profit);
    // old Code
    // const grandTotal = parseFloat(itemTotal) + parseFloat(totalShipping) + parseFloat(totalTax);

    // total fee
    for (const i in this.itemNames) {
      if (this.itemNames[i].isFee || false) {
        totalFee += this.itemNames[i].price;
      }
    }

    const grandTotal =
      itemTotal + parseFloat(totalShipping) + parseFloat(totalTax.toString()); // + totalFee;

    const summary = {
      itemTotal,
      totalTax,
      totalShipping,
      includeVarCost,
      profit,
      estPercent,
      totalCost,
      grandTotal
    };

    this.$emit("summaryChange", summary);
    this.updateDistyTotal();

    return summary;
  }

  marginCalc1(index) {
    const item = this.itemNames[index];
    const currentMargin = item.margin;
    if (item.customerPrice) {
      // Comment out fopt EST should not affect margin values
      // if (this.$parent.summary.estPercent == 0) {
      //just canculate margin in case no using EST%
      item.margin = roundCentsWithDecimal(item.customerPrice - item.price);
      this.reCalculateMargin(item, "rate");
      // }
      item.total = roundCents(item.customerPrice * item.quantity);
      // if(roundCents(item.customerPrice - item.price) && item.price > 0){
      //   item.marginRate = roundCents(item.customerPrice - item.price)/item.price* 100;
      // }
      item.orgCustomerPrice = item.customerPrice;
    } else {
      item.customerPrice = 0;
      item.margin = 0;
      item.marginRate = 0;
      item.orgCustomerPrice = 0;
    }

    // if(item.included){
    //   const parent = findParent(index, this.itemNames);
    //   if(parent) {
    //     const parentIndex = this.findParentIndex(index, this.itemNames);
    //     var includedCustomerPrice = 0;
    //     for (let i = parentIndex + 1; i < Object.entries(this.itemNames).length; i++) {
    //       const lineItem = this.itemNames[`productName_${i}`];
    //       if (!lineItem) {
    //         continue;
    //       }
    //       if (lineItem.included){
    //         includedCustomerPrice += lineItem.customerPrice;
    //       } else {
    //         break;
    //       }
    //     }
    //     parent.customerPrice = includedCustomerPrice;
    //     parent.margin = roundCentsWithDecimal(parent.customerPrice - parent.price);
    //     this.reCalculateMargin(parent, "rate");
    //   }
    // }

    item.priceWarn = false;
    //this.$parent.$refs.OrderFormSummary.edit('estPercent',1);
    //this.taxRate(index);
    item.tax = this.getLineItemTax(item);
    this.$parent.comeFrom = "price_per"; //user changes customer price
    this.updateSummary();
    this.reCalculateGlobalMargin(); //re-calculate for global margin
    this.checkItemChangedValue(index, "margin", currentMargin, item.margin);
  }

  marginCalc2(index) {
    // if(this.marginSwitch && this.itemNames[index].price > 0){
    //   this.itemNames[index].margin = ((this.itemNames[index].marginRate/100)* this.itemNames[index].price);
    // }
    const currentCustomerPrice = this.itemNames[index].customerPrice;
    if (this.itemNames[index].margin) {
      if (this.itemNames[index].margin < 0) {
        if (
          Math.abs(this.itemNames[index].margin) >= this.itemNames[index].price
        ) {
          this.itemNames[index].margin = -Math.abs(this.itemNames[index].price);
        }
      }
      this.itemNames[index].customerPrice =
        this.itemNames[index].margin + this.itemNames[index].price;

      // this.itemNames[index].total = this.itemNames[index].customerPrice;
      // updated for tax calculate when update margin
      this.itemNames[index].total = roundCents(
        this.itemNames[index].customerPrice * this.itemNames[index].quantity
      );
      // if(roundCents(this.itemNames[index].customerPrice - this.itemNames[index].price) && this.itemNames[index].price > 0 && !this.marginSwitch){
      //   this.itemNames[index].(marginRate) = roundCents(this.itemNames[index].customerPrice - this.itemNames[index].price)/this.itemNames[index].price* 100;
      // }
      this.reCalculateMargin(this.itemNames[index], "rate");
    } else {
      this.itemNames[index].margin = 0.0;
      this.itemNames[index].marginRate = 0;
      this.itemNames[index].customerPrice = this.itemNames[index].price;
    }
    //this.$parent.$refs.OrderFormSummary.edit('estPercent',1);
    //this.taxRate(index);
    this.itemNames[index].orgCustomerPrice =
      this.itemNames[index].customerPrice;
    this.itemNames[index].tax = this.getLineItemTax(this.itemNames[index]);
    // this.setIncludedItemPrice(false);
    this.updateSummary();
    this.reCalculateGlobalMargin(); //re-calculate for global margin
    this.checkItemChangedValue(
      index,
      "price",
      currentCustomerPrice,
      this.itemNames[index].customerPrice
    );
  }

  totalCal(index, type) {
    if (this.itemNames[index].quantity) {
      this.itemNames[index].quantityBk = this.itemNames[index].quantity;
    }
    this.itemNames[index].total =
      this.itemNames[index].quantity * this.itemNames[index].customerPrice;

    // Comment out fopt EST should not affect margin values
    // if (this.$parent.summary.estPercent == 0) {
    //just canculate margin in case no using EST%
    this.itemNames[index].margin =
      this.itemNames[index].customerPrice - this.itemNames[index].price;
    // }

    this.itemNames[index].tax = this.getLineItemTax(this.itemNames[index]);
    // this.itemNames[index].customerPrice *
    // this.itemNames[index].quantity *
    // (this.itemNames[index].taxRate / 100);

    this.$parent.comeFrom = "qty"; //user changes quantity
    this.updateSummary(type);
    this.reCalculateGlobalMargin();
  }

  totalShipTax(index, type) {
    var total = this.itemNames[index].total;
    if (type == "tax") {
      this.itemNames[index].taxRate =
        total === 0 ? 0 : (this.itemNames[index].tax / total) * 100;

      //just allow 4 decimals for taxRate
      var tmpTaxRate = this.itemNames[index].taxRate.toString();
      if (
        tmpTaxRate.indexOf(".") != -1 &&
        tmpTaxRate.split(".")[1].length > 4
      ) {
        this.itemNames[index].taxRate = parseFloat(
          this.itemNames[index].taxRate.toFixed(4)
        );
      }

      this.itemNames[index]["taxRate_bk"] = this.itemNames[index].taxRate; //backup inputted tax rate
      this.$parent.USECUSTOMTAX = 0; //user changed something for tax amount
      this.updateSummary();
    } else if (type == "shipping") {
      this.updateSummary();
    }
  }

  taxRate(index) {
    //maximum tax rate = 100%
    if (this.itemNames[index].taxRate > 100) {
      this.itemNames[index].taxRate = 100;
    }

    var total = this.itemNames[index].total;
    this.itemNames[index].tax = parseFloat(
      (total * (this.itemNames[index].taxRate / 100)).toFixed(2)
    ); //same as calculation at quote

    this.itemNames[index]["taxRate_bk"] = this.itemNames[index].taxRate; //backup inputted tax rate
    this.$parent.USECUSTOMTAX = 0; //user changed something for tax rate
    this.updateSummary();
  }

  marginRate(index) {
    var item = this.itemNames[index];
    if (!isNaN(item.marginRate)) {
      const currentCustomerPrice = item.customerPrice;
      if (item.marginRate < -100) {
        //no allow < -100%
        item.marginRate = -100.0;
      }
      item.customerPrice = this.getCustomerPriceByMarinRate(item);
      item.orgCustomerPrice = item.customerPrice;
      this.reCalculateMargin(item, "amount");
      // item.customerPrice = item.margin + item.price;
      // item.total = parseFloat((item.customerPrice * item.quantity).toFixed(5));
      item.total =
        parseFloat(
          item.customerPrice.toFixed(this.$parent.expandDecimalPrecision)
        ) * item.quantity;
      //this.taxRate(index);
      item.tax = this.getLineItemTax(this.itemNames[index]);
      // this.setIncludedItemPrice(false);
      this.updateSummary();
      this.reCalculateGlobalMargin(); //re-calculate for global margin
      this.checkItemChangedValue(
        index,
        "price",
        currentCustomerPrice,
        item.customerPrice
      );
    }
  }

  marginRateBlur(index) {
    var item = this.itemNames[index];
    if (isNaN(item.marginRate)) {
      item.marginRate = 0;
      this.marginRate(index);
    }
  }

  isNumber(evt) {
    evt = evt ? evt : window.event;
    var charCode = evt.which ? evt.which : evt.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      evt.preventDefault();
      return false;
    } else {
      return true;
    }
  }

  onSkuSelected(
    index: string,
    data: {
      option: Option;
      line: QuoteLineItemList.Lineitems;
    }
  ) {
    this.itemNames[index].name = data.line.QPRODDESC;
    this.itemNames[index].price = data.line.QPRICEREG;
    this.itemNames[index].customerPrice = data.line.QPRICEREG;
  }

  onNameSelected(
    index: string,
    data: {
      option: Option;
      line: QuoteLineItemList.Lineitems;
    }
  ) {
    this.itemNames[index].sku = data.line.QPRODSKU;
    this.itemNames[index].price = data.line.QPRICEREG;
    this.itemNames[index].customerPrice = data.line.QPRICEREG;
  }

  removeItem(index) {
    const removedIndex = parseInt(index.split("_")[1]);
    const item = this.itemNames[index];
    this.$delete(this.itemNames, index);
    if (this.length > 0) {
      this.length -= 1;
    }

    // if remove a parent, remove config items inside too
    if (!item.included) {
      for (let key in this.itemNames) {
        let tmpIndex = parseInt(key.split("_")[1]);
        if (tmpIndex > removedIndex) {
          if (!this.itemNames[key].included) {
            break;
          }

          if (this.itemNames[key].ISCONFIG) {
            this.$delete(this.itemNames, key);
          } else {
            // exclude grouped item
            this.itemNames[key].included = 0;
          }
        }
      }
    }

    if (item.included === 1) {
      const includedItems = findOtherIncludedItems(index, this.itemNames);
      if (includedItems.length === 0) {
        const parent = findParent(index, this.itemNames);
        if (parent) {
          parent.priceWarn = false;
        }
      }
    }
    // var newitems = {};
    // var newIt = 1;
    // for (var ky in this.itemNames) {
    //   newitems["productName_" + newIt] = this.itemNames[ky];
    //   newIt += 1;
    // }
    // this.itemNames = newitems;

    // Set included price based on after removing line item
    // this.setIncludedItemPrice(false);

    // case multi subset empty line items, auto add a new line item
    if (!this.convertFromMultiSubSets) {
      if (item.subOrderId || 0) {
        let cnt = 0;
        for (const i in this.itemNames) {
          if ((this.itemNames[i].subOrderId || 0) == item.subOrderId) {
            cnt++;
          }
        }
        if (cnt == 0) {
          // auto add a new line item for this subset
          this.addRow(false, item.subOrderId);
        }
      }
    }

    this.updateLineItems();
    this.updateSummary();
    if (!this.$parent.confirmedGlobalMargin) {
      this.reCalculateGlobalMargin();
    }
  }

  includeItem(index) {
    // disable include/exclude top line
    if (this.findIdx(index) == 0) {
      return;
    }

    const item = this.itemNames[index];
    if (
      (typeof item.isFee != "undefined" && item.isFee) ||
      item.isContractFee
    ) {
      return;
    }

    // prevent for some line item status
    if (item.statusid == 1 || item.statusid == 2) {
      return;
    }

    const configCategoryId = this.$parent.configCategory.CATEGORYID || 0;
    // toggle included
    item.included = (item.included || 0) == 0 ? 1 : 0;

    // process to include/exclude to base
    if (!item.included) {
      // $("#included_" + index)
      //   .removeClass("checkbox_checked")
      //   .addClass("checkbox_unchecked");
      if (item.ItemCategory == configCategoryId || item.ISCONFIG || 0) {
        item.ISCONFIG = 0;
        item.ItemCategory = 0;
        item.ItemCategoryName = "";
        item.ItemCategoryBk = 0;
        item.ItemCategoryNameBk = "";
      }
    } else {
      // $("#included_" + index)
      //   .removeClass("checkbox_unchecked")
      //   .addClass("checkbox_checked");
      // check if item is config
      if (
        configCategoryId > 0 &&
        (item.ItemCategory || 0) == configCategoryId
      ) {
        item.ISCONFIG = 1;

        // if item is config, follow base taxRate
        const parent = findParent(index, this.itemNames);
        if (parent) {
          // item.quantity = item.quantityBk = parent.quantity || 0;
          item.taxRate = parent.taxRate || 0;
          item.total = (item.customerPrice || 0) * (item.quantity || 0);
        }
      }
    }

    var lineItemInd = index;
    // if ($("#included_" + index).hasClass("checkbox_checked")) {
    if (!item.included) {
      // uncheck item
      $("#included_" + index)
        .closest(".list-item")
        .find("input")
        .each(function (this: Window, index, el) {
          //in case textbox is "tax amount" or "tax rate", just enable if noTax is no using
          if ($(el).hasClass("taxRate") || $(el).hasClass("taxAmount")) {
            if ($("#noTax_" + lineItemInd).hasClass("checkbox_unchecked")) {
              $(this).removeClass("disabled").prop("disabled", false);
            }
          } else if (!$(el).hasClass("price_margin")) {
            //margin textbox always disable
            $(this).removeClass("disabled").prop("disabled", false);
          }
        });

      // $("#included_" + index)
      //   .removeClass("checkbox_checked")
      //   .addClass("checkbox_unchecked");
      // item.included = 0;
      // item.quantity = item.quantityBk || 0;
      // item.ItemCategory = item.ItemCategoryBk || 0;
      // item.ItemCategoryName = item.ItemCategoryNameBk || "";
      // if(item.ItemCategory == configCategoryId || (item.ISCONFIG || 0)) {
      //   item.ISCONFIG = 0;
      //   item.ItemCategory = 0;
      //   item.ItemCategoryName = "";
      //   item.ItemCategoryBk = 0;
      //   item.ItemCategoryNameBk = "";
      // }
    } else {
      // check this item
      // $("#included_" + index)
      //   .closest(".list-item")
      //   .find("input")
      //   .each(function(index, el) {
      //     // $(this).addClass('disabled').prop('disabled', true);
      //   });

      // $("#included_" + index)
      //   .removeClass("checkbox_unchecked")
      //   .addClass("checkbox_checked");
      item.quantityBk = item.quantity;
      // item.included = 1;
      // item.quantity = 0;
      // item.taxRate  = 0;

      // set config category for this item
      // if(configCategoryId > 0) {
      //   if((item.ItemCategory || 0) != configCategoryId) {
      //     item.ItemCategoryBk = item.ItemCategory || 0;
      //     item.ItemCategoryNameBk = item.ItemCategoryName || "";
      //   }

      //   item.ItemCategory = configCategoryId;
      //   item.ItemCategoryName = configCategoryName;
      // }

      // check if item is config
      // if(configCategoryId > 0 && (item.ItemCategory || 0) == configCategoryId) {
      //   item.ISCONFIG = 1;
      // }
    }

    // for (const lineItem in this.itemNames) {
    //   var lineItemFields = this.itemNames[lineItem];
    //   if(!lineItemFields.included) {
    //     if(this.$parent.includedItem) {
    //       this.$parent.includedItem = false;
    //     }
    //     continue;
    //   }
    //   this.$parent.includedItem = true;
    //   break;
    // }

    this.$parent.includedItem = false;
    for (const i in this.itemNames) {
      if (this.itemNames[i].included) {
        this.$parent.includedItem = true;
        break;
      }
    }

    if (item.included === 0) {
      const includedItems = findOtherIncludedItems(index, this.itemNames);
      const parent = findParent(index, this.itemNames);
      if (parent && includedItems.length === 0) {
        parent.priceWarn = false;
        parent.disable = false;
        parent.price = parent.orgPrice;
        parent.customerPrice = parent.orgCustomerPrice;
        parent.margin = roundCentsWithDecimal(
          parent.customerPrice - parent.price
        );
        this.reCalculateMargin(parent, "rate");
      }
    }

    // Set included price based on switch
    // this.setIncludedItemPrice();

    // if(!this.$parent.includedItem) {
    //   this.$parent.includedPrice = true;
    // }
    this.updateLineItems();
    this.updateSummary();
    if (!this.$parent.confirmedGlobalMargin) {
      this.reCalculateGlobalMargin();
    }
    //this.$parent.USECUSTOMTAX = 0;//total tax need to be calculated if user check/uncheck included option
  }

  noTax(index) {
    var checkbox = "#noTax_" + index;
    if ($(checkbox).hasClass("checkbox_checked")) {
      //unchecked
      $(checkbox)
        .removeClass("checkbox_checked")
        .addClass("checkbox_unchecked");
      this.itemNames[index].noTax = false;

      //enalbe related taxRate/tax box
      if ($("#included_" + index).hasClass("checkbox_unchecked")) {
        $("#tax_rate_" + index + ", #tax_" + index)
          .prop("disabled", false)
          .removeClass("disabled");
      }
    } else if ($(checkbox).hasClass("checkbox_unchecked")) {
      //checked
      $(checkbox)
        .removeClass("checkbox_unchecked")
        .addClass("checkbox_checked");
      this.itemNames[index].noTax = true;

      //disable related taxRate/tax box
      $("#tax_rate_" + index + ", #tax_" + index)
        .prop("disabled", true)
        .addClass("disabled");
    }

    this.$parent.USECUSTOMTAX = 0; //total tax need to be calculated if user check/uncheck noTax option
    this.updateSummary();
  }

  checkAllTaxRate() {
    this.checkAllTax = !this.checkAllTax;
    for (const key in this.itemNames) {
      if (!this.itemNames[key].ISCONFIG || 0) {
        if (this.$parent.currentTaxRate != 0) {
          this.itemTaxable(key, this.checkAllTax);
        } else {
          this.checkAllTax = false;
          this.taxRateVisible = true;
        }
      }
    }
  }

  checkForUnselected() {
    let unSelectedforTax = {};
    for (let key in this.itemNames) {
      if (this.itemNames[key].taxRate == 0) {
        unSelectedforTax[key] = this.itemNames[key];
      }
    }
    if (Object.keys(unSelectedforTax).length == 0) {
      this.checkAllTax = true;
    }
  }

  itemTaxable(index, checkAll = false) {
    const item = this.itemNames[index];
    // no allow click on this item when using custom tax
    // if(this.$parent.USECUSTOMTAX || (typeof item.isFee != "undefined" && item.isFee)) {
    //   return;
    // }

    // prevent for some line item status
    if (
      this.$route.name != "DuplicateOrder" &&
      (item.statusid == 1 || item.statusid == 2)
    ) {
      return;
    }

    // disable tax on fee
    // (item.included && item.ISCONFIG)
    if (this.isFee(item)) {
      return;
    }

    // reopen tax rate box if current tax rate = 0 before
    if (
      (item.taxRate == 0 && this.$parent.currentTaxRate == 0) ||
      this.$parent.USECUSTOMTAX
    ) {
      if (this.detailsView == "sku-sum") {
        // force this in groupItemsChange
        return;
      }
      this.$parent.checkAutomateTaxRate();
      this.taxRateVisible = true;
      this.selectedIndexForTax = index;
      return;
    }

    if (this.$parent.USECUSTOMTAX) {
      return;
    }

    // const checkbox = "#taxable_" + index;
    // if ($(checkbox).hasClass("checkbox_checked")) {
    //   // unchecked
    //   item.taxRate = 0;
    // } else if ($(checkbox).hasClass("checkbox_unchecked")) {
    //   // checked
    //   item.taxRate = this.$parent.currentTaxRate;
    // }
    if (item.taxRate == 0) {
      // checked
      item.taxRate = this.$parent.currentTaxRate;
    } else if (!checkAll) {
      // unchecked
      this.checkAllTax = false;
      item.taxRate = 0;
    }

    item.tax = this.getLineItemTax(item);

    // config items have same taxRate as parent
    const configItems = this.getIncludedItems(item, index).filter(
      t => t.ISCONFIG || 0
    );
    for (const t of configItems) {
      t.taxRate = item.taxRate;
      t.tax = this.getLineItemTax(t);
    }

    this.checkForUnselected();
    this.$parent.USECUSTOMTAX = 0;
    this.updateSummary();
  }

  calcWithChangeCostPrice(index) {
    const item = this.itemNames[index];
    const currentMargin = item.margin;

    item.priceWarn = false;
    item.orgPrice = item.price;
    // if(item.included){
    //   const parent = findParent(index, this.itemNames);
    //   if(parent) {
    //     const parentIndex = this.findParentIndex(index, this.itemNames);
    //     var includedPrice = 0;
    //     for (let i = parentIndex + 1; i < Object.entries(this.itemNames).length; i++) {
    //       const lineItem = this.itemNames[`productName_${i}`];
    //       if (!lineItem) {
    //         continue;
    //       }
    //       if (lineItem.included){
    //         includedPrice += lineItem.price;
    //       } else {
    //         break;
    //       }
    //     }
    //     parent.price = parent.orgPrice = includedPrice;
    //     parent.margin = roundCentsWithDecimal(parent.customerPrice - parent.price);
    //     this.reCalculateMargin(parent, "rate");
    //   }
    // }
    if (this.$parent.isIndirect() && this.$parent.confirmedGlobalMargin) {
      // confirmed a global margin
      if (!(item.isFee || item.isContractFee || false)) {
        const currentCustomerPrice = item.customerPrice;
        // calculate customer price first
        item.customerPrice = this.getCustomerPriceByMarinRate(item);
        this.reCalculateMargin(item, "amount");
        // item.customerPrice = parseFloat((item.price + item.margin).toFixed(this.$decimalsView));
        this.checkItemChangedValue(
          index,
          "price",
          currentCustomerPrice,
          item.customerPrice
        );
      } else {
        // is fee
        item.customerPrice = 0;
        // item.margin = parseFloat((item.customerPrice - item.price).toFixed(2));
        // this.reCalculateMargin(item, "rate");
        item.margin = 0;
        item.marginRate = 0;
      }
      this.updateSummary();
      if (item.isFee || item.isContractFee || false) {
        this.reCalculateGlobalMargin(); //re-calculate for global margin
      }
    } else {
      // if (this.itemNames[index].customerPrice) {
      // Comment out fopt EST should not affect margin values
      // if (this.$parent.summary.estPercent == 0) {
      //just canculate margin in case no using EST%
      // this.itemNames[index].margin = roundCents(
      //   this.itemNames[index].customerPrice - this.itemNames[index].price
      // );
      // if(roundCents(this.itemNames[index].customerPrice - this.itemNames[index].price)){
      //   this.itemNames[index].marginRate = roundCents(this.itemNames[index].customerPrice - this.itemNames[index].price)/this.itemNames[index].price* 100;
      // }
      // this.reCalculateMargin(this.itemNames[index], "rate");
      // }
      // } else {
      //   this.itemNames[index].customerPrice = 0;
      //   this.itemNames[index].margin = 0;
      //   this.itemNames[index].marginRate = 0;
      // }
      //this.$parent.$refs.OrderFormSummary.edit('estPercent',1);
      // this.itemNames[index].priceWarn = false;

      if (!(item.isFee || item.isContractFee || false)) {
        this.itemNames[index].margin = roundCentsWithDecimal(
          this.itemNames[index].customerPrice - this.itemNames[index].price
        );
        this.reCalculateMargin(this.itemNames[index], "rate");
      } else {
        // is fee
        item.customerPrice = 0;
        item.margin = 0;
        item.marginRate = 0;
      }

      this.$parent.comeFrom = "cost_per"; //user changes cost per
      this.updateSummary();
      this.reCalculateGlobalMargin(); //re-calculate for global margin
    }
    item.orgCustomerPrice = item.customerPrice;
    this.checkItemChangedValue(index, "margin", currentMargin, item.margin);
  }

  addRow(scroll = true, subId = 0) {
    this.length = this.length + 1;

    const keys = Object.keys(this.itemNames);
    const lastKey = keys.pop();
    const lastItem = this.itemNames[lastKey || ""];
    const sourceId = lastItem.sourceId || 0;

    const item: ItemName = {
      sku: "",
      name: "",
      price: 0,
      customerPrice: 0,
      margin: 0,
      marginRate: this.$parent.getConfirmedGlobalMargin(true),
      total: 0,
      quantity: 0,
      tax: 0,
      taxRate: this.$parent.USECUSTOMTAX ? 0 : this.$parent.currentTaxRate,
      shipping: 0,
      included: 0,
      priceWarn: false,
      categoryType: 0,
      noTax: false,
      isFee: false,
      dynamicInfo: {},
      statusid: 0,
      contractPrice: undefined,
      contractPriceDate: undefined,
      orgPrice: 0,
      orgCustomerPrice: 0,
      disable: false,
      isContractFee: false,
      includedparent: 0,
      baseProductId: 0,
      includeItemCount: 0,
      buildHeader: "",
      selectedSource: [sourceId],
      sourceId,
      subOrderId: subId
    };
    const newIndex = `productName_${this.getMaxIndex() + 1}`;
    this.$set(this.itemNames, newIndex, item);

    // var newitems = {};
    // var newIt = 1;
    // for (var ky in this.itemNames) {
    //   newitems["productName_" + newIt] = this.itemNames[ky];
    //   newIt += 1;
    // }
    // this.itemNames = newitems;

    if (scroll) {
      setTimeout(() => {
        $("html, body").animate(
          { scrollTop: $("html, body").get(0).scrollHeight },
          200
        );
      }, 100);
    }
  }

  addDelete(index) {
    $(".list-item[data-index='" + index + "'] .deleteIcon")
      .removeClass("displayNone")
      .css("display", "inline-block");
    // var regex = /[^a-zA-Z0-9#-]/gi;
    // var indArr: number[] = [];
    // var maxInd = 0;
    // $.each(this.itemNames, function(i, val) {
    //   indArr.push(parseInt(i.replace("productName_", "")));
    // });
    // if (indArr.length) {
    //   maxInd = Math.max.apply(Math, indArr);
    // }
    // if (
    //   parseInt(index.replace("productName_", "")) == this.length ||
    //   parseInt(
    //     Object.keys(this.itemNames)[
    //       Object.keys(this.itemNames).length - 1
    //     ].split("_")[1]
    //   ) == parseInt(index.replace("productName_", ""))
    // ) {
    //   this.addRow();
    // }

    // var newitems = {};
    // var newIt = 1;
    // for (var ky in this.itemNames) {
    //   newitems["productName_" + newIt] = this.itemNames[ky];
    //   newIt += 1;
    // }
    // this.itemNames = newitems;

    // auto add new row if input at the end of the items
    if (this.isEndItem(index)) {
      this.addRow();
    }
  }

  taxSwitch() {
    if (!$(".tax.editable-new-quote").hasClass("currencyInput")) {
      $(".tax.editable-new-quote").addClass("currencyInput");
      $(
        ".tax.editable-new-quote .left, .tax.editable-new-quote .taxAmount"
      ).removeClass("displayNone");
      $(".tax.editable-new-quote .taxRate").addClass("displayNone");
    } else {
      $(".tax.editable-new-quote").removeClass("currencyInput");
      $(
        ".tax.editable-new-quote .left, .tax.editable-new-quote .taxAmount"
      ).addClass("displayNone");
      $(".tax.editable-new-quote .taxRate").removeClass("displayNone");
    }
  }
  marginSwitch() {
    this.marginInput = this.marginInput == "amount" ? "rate" : "amount";
  }

  public setRows(rows: ItemName[], pOrderList = {}) {
    this.length = 0;
    this.orderList = pOrderList;

    this.itemNames = {};

    let baseProductId: any = 0;

    for (const val of rows) {
      this.length = this.length + 1;

      if (val.ISCONFIG == 0) {
        baseProductId = val.sku;
      }

      this.$set(this.itemNames, "productName_" + this.length, {
        sku: val.sku,
        name: val.name,
        price: val.price || 0,
        customerPrice: val.customerPrice || 0,
        margin: val.margin,
        marginRate: typeof val.marginRate != "undefined" ? val.marginRate : 0,
        total: val.total || 0,
        quantity: val.quantity,
        quantityBk: val.quantityBk || val.quantity,
        tax: val.tax,
        taxRate: val.taxRate || 0,
        shipping: val.shipping,
        included: val.included || 0,
        ISCONFIG: val.ISCONFIG || 0,
        categoryType: val.categoryType || 0,
        priceWarn: false,
        noTax: typeof val.noTax != "undefined" && val.noTax ? true : false,
        poLiID: typeof val["poLiID"] != "undefined" ? val["poLiID"] : 0,
        DistyPrice:
          typeof val["DistyPrice"] != "undefined" ? val["DistyPrice"] : 0,
        DistyAvailability:
          typeof val["DistyAvailability"] != "undefined"
            ? val["DistyAvailability"]
            : 0,
        DistyPrice_Format:
          typeof val["DistyPrice_Format"] != "undefined"
            ? val["DistyPrice_Format"]
            : "",
        DistyAvailability_Format:
          typeof val["DistyAvailability_Format"] != "undefined"
            ? val["DistyAvailability_Format"]
            : "",
        Disty: typeof val["Disty"] != "undefined" ? val["Disty"] : "",
        ItemCategory:
          typeof val["ItemCategory"] != "undefined" ? val["ItemCategory"] : 0,
        ItemCategoryName:
          typeof val["ItemCategoryName"] != "undefined"
            ? val["ItemCategoryName"]
            : "",
        isFee: val.isFee || false,
        dynamicInfo: val.dynamicInfo || {},
        statusid: typeof val.statusid != "undefined" ? val.statusid : 0,
        contractPrice:
          typeof val["contractPrice"] != undefined
            ? val["contractPrice"]
            : undefined,
        contractPriceDate:
          typeof val["contractPriceDate"] != undefined
            ? val["contractPriceDate"]
            : undefined,
        orgPrice: val.orgPrice || 0,
        orgCustomerPrice: val.orgCustomerPrice || 0,
        disable: val.disable,
        isContractFee: val.isContractFee || false,
        includedparent: val.includedparent,
        baseProductId: baseProductId || 0,
        includeItemCount: val.includeItemCount,
        quoteLiId: val.quoteLiId || 0,
        quoteParentLiId: val.quoteParentLiId || 0,
        parentLiId: val.parentLiId || 0,
        quoteId: val.quoteId || "",
        subQuoteId: val.subQuoteId || 0,
        subOrderId: val.subOrderId || 0,
        CONFIGCOUNT: val.CONFIGCOUNT || 0,
        savedAsConfig: val.savedAsConfig || 0,
        ItemPLID: val.ItemPLID || 0,
        ItemPLName: val.ItemPLName || "",
        buildHeader: val.buildHeader || "",
        selectedSource: val.selectedSource || [],
        sourceId: val.sourceId || 0
      });
    }

    this.updateLineItems();
  }

  public getItemNames(): ItemNames {
    return this.itemNames;
  }

  async validateAll(): Promise<false | ItemNames> {
    const rowResults = await Promise.all(
      this.$refs.rows.map(row => row.validateAll())
    );
    const invalid = rowResults.some(r => r === "invalid");
    if (invalid) {
      return false;
    }

    const itemNames: ItemNames = {};
    var blankItems = 0;
    var result = true;
    var itemLength = Object.keys(this.itemNames).length;
    var lineItemLength = 0;
    for (const rowResult of rowResults) {
      if (rowResult === "blank") {
        blankItems += 1;
      }
      if (rowResult === "blank" || rowResult === "invalid") {
        continue;
      }

      if (!rowResult.item.isFee) {
        lineItemLength++;
      }

      itemNames[rowResult.index] = rowResult.item;
    }

    if (lineItemLength == 0) {
      $("#errorMsgLine").html(
        "Let's enter at least one line item, other than FEE"
      );
      return false;
    } else {
      $("#errorMsgLine").html("");
    }

    if (Object.keys(itemNames).length === 0) {
      $("#errorMsgLine").html(
        "Let's enter enough info about SKU, Product, Cost and Quantity, ..."
      );
      return false;
    } else {
      $("#errorMsgLine").html("");
    }
    if (result) {
      return itemNames;
    } else {
      return false;
    }
  }

  usedCustomTax() {
    //return from api, or determin by change textbox custom tax
    if (this.$parent.USECUSTOMTAX != "undefined") {
      return this.$parent.USECUSTOMTAX; //1 or 0
    }

    return 0;
  }

  htmlCheck(InputVal) {
    if (InputVal.length > htmlParse(InputVal).length) {
      return true;
    } else {
      return false;
    }
  }
  async SKULookup(index, item) {
    // Check if the fee tooltip is visible or if the item is a fee.
    const feeCategory: any = this.catList.find(
      (t: any) => t.CATEGORYNAME == "Fee"
    );
    if (
      this.feeVisibleIndex == index ||
      (typeof item.isFee != "undefined" && item.isFee)
    ) {
      if (feeCategory) {
        item.ItemCategory = feeCategory.CATEGORYID || 0;
        item.ItemCategoryName = feeCategory.CATEGORYNAME || "";
      }
      return;
    }

    // Show loaders for name and cost if applicable.
    if (this.autoName == 1) {
      this.toggleLoader(index, ".new-quote-name-input .loader", false);
    }
    if (this.autoCost == 1) {
      this.toggleLoader(index, ".new-quote-cost-input .loader", false);
    }

    // Check and proceed only if SKU is defined and not empty.
    if (item.sku && item.sku.trim() !== "") {
      try {
        // Make an AJAX request to the CFM file.
        let response: any = await this.fetchProductInfo(item.sku);

        // Handle the response
        if (response.data.LINEITEMS && response.data.LINEITEMS.length > 0) {
          let productInfo = response.data.LINEITEMS;

          // Update item details based on response.
          if (this.autoName == 1 && productInfo[0].PRODUCTNAME != "") {
            // eslint-disable-next-line require-atomic-updates
            item.name = productInfo[0].PRODUCTNAME;
          }
          if (this.autoCost == 1 && productInfo.PRODUCTPRICE) {
            // eslint-disable-next-line require-atomic-updates
            item.price = productInfo.PRODUCTPRICE;
          }
          const categoryId = productInfo[0].DEFAULTCATEGORYID || 0;
          const categoryName = productInfo[0].CATEGORYNAME || "";
          if (categoryId) {
            if (this.idxOf(index) == 0 && categoryName == "Config") {
              // do nothing, if detect sku is a config sku but the line is top of list
            } else {
              // eslint-disable-next-line require-atomic-updates
              item["ItemCategory"] = categoryId;
              // eslint-disable-next-line require-atomic-updates
              item["ItemCategoryName"] = categoryName;
            }
          } else {
            // eslint-disable-next-line require-atomic-updates
            item["ItemCategory"] = 0;
            // eslint-disable-next-line require-atomic-updates
            item["ItemCategoryName"] = "";
            this.productCatTTVisibleIndex = index; //show product category tooltip
          }
          if (typeof productInfo[0].ContractPrice != "undefined") {
            // eslint-disable-next-line require-atomic-updates
            item["contractPrice"] = productInfo[0].ContractPrice;
          }
          if (
            index != "productName_1" &&
            productInfo[0].ISCONFIG &&
            productInfo[0].ISCONFIG == 1
          ) {
            // eslint-disable-next-line require-atomic-updates
            item["ISCONFIG"] = 1;
            // eslint-disable-next-line require-atomic-updates
            item["included"] = 1;
          }

          if (typeof productInfo[0].PRODUCTLINEID != "undefined") {
            // eslint-disable-next-line require-atomic-updates
            item["ItemPLID"] = productInfo[0].PRODUCTLINEID;
          }
          if (typeof productInfo[0].PRODUCTLINENAME != "undefined") {
            // eslint-disable-next-line require-atomic-updates
            item["ItemPLName"] = productInfo[0].PRODUCTLINENAME;
          }

          this.updateLineItems();
        }
      } catch (error) {
        console.error("Error during SKU lookup:", error);
      } finally {
        this.toggleLoader(index, ".new-quote-name-input .loader", true);
        this.toggleLoader(index, ".new-quote-cost-input .loader", true);
      }
    }
  }

  toggleLoader(index, selector, hide) {
    let loaderElement = document.querySelector(
      `.list-item[data-index="${index}"] ${selector}`
    );
    if (loaderElement) {
      loaderElement.classList.toggle("hidden", hide);
    }
  }

  async fetchProductInfo(sku) {
    var dataObj = {
      controller: "Quotes",
      FunctionName: "quoteLineItemList",
      search: sku,
      source: this.$parent.sourceID,
      contractNumberValue: this.$parent.selectedOrderContracts,
      contractNumberId: this.$parent.orderContractsID.join(),
      autoName: this.autoName,
      autoCost: this.autoCost
    };

    try {
      let response = await getRouteData(dataObj);
      return response;
    } catch (error) {
      console.error("Error in fetchProductInfo:", error);
      this.checkingSKU = false;
      return null;
    }
  }

  notHPSource() {
    if (
      this.$parent.sourceID != 0 &&
      this.$parent.sourceID != 1 &&
      this.$parent.sourceID != 12
    ) {
      return true;
    }
    return false;
  }
  selectingDisty(disty) {
    if (typeof disty == "undefined" || disty == "") {
      return false;
    }

    if (disty.toLowerCase() == this.$parent.source.toLowerCase()) {
      return true;
    }
    return false;
  }
  clearDistyError() {
    for (var i in this.itemNames) {
      this.itemNames[i]["distyError"] = false;
    }
  }
  checkDistyError() {
    //check cost price with disty price
    for (var i in this.itemNames) {
      if (
        this.autoCost == 1 &&
        this.notHPSource() &&
        this.selectingDisty(this.itemNames[i]["Disty"]) &&
        typeof this.itemNames[i]["DistyPrice"] != "undefined" &&
        this.itemNames[i]["DistyPrice"] > 0
      ) {
        if (this.itemNames[i].price == 0) {
          this.itemNames[i].price = this.itemNames[i].orgPrice =
            this.itemNames[i]["DistyPrice"];
        }
      }
    }
  }
  autoNameSwitch(val) {
    this.autoName = val;
  }
  autoCostSwitch(val) {
    this.autoCost = val;

    if (val == 1) {
      //enable auto price
      this.checkDistyError();
    } else {
      this.clearDistyError();
    }
  }

  async updateSource() {
    this.clearDistyError();
    if (this.notHPSource()) {
      //get disty info
      var search: string[] = [];
      for (var i in this.itemNames) {
        if (
          this.itemNames[i].sku != "" &&
          !search.includes(this.itemNames[i].sku)
        ) {
          search.push(this.itemNames[i].sku);
        }
      }

      if (search.length) {
        this.$parent.loading = true;
        try {
          const response = await axios.post(dataURL + "?ReturnType=JSON", {
            controller: "Quotes",
            FunctionName: "quoteLineItemList",
            search: search,
            source: this.$parent.sourceID,
            contractNumberValue: this.$parent.selectedOrderContracts,
            contractNumberId: this.$parent.orderContractsID.join()
          });

          if (response.data.ERROR) {
            throw new Error(response.data.ERROR);
          }
          if (response.data.STATUS !== 1) {
            throw new Error(response.data.STATUSMESSAGE);
          }

          if (
            response.data.STATUS == 1 &&
            typeof response.data.LINEITEMS != "undefined"
          ) {
            //update disty info
            for (let j in this.itemNames) {
              if (this.itemNames[j].isFee || false) {
                continue;
              }
              let productInfo = response.data.LINEITEMS.filter(
                val => val.PRODUCTSKU == this.itemNames[j].sku
              );
              if (productInfo.length) {
                productInfo = productInfo[0];

                // case dynamic source
                // if(this.$parent.isDynamic()) {
                this.itemNames[j].dynamicInfo = productInfo;
                // }

                if (typeof productInfo.Disty != "undefined") {
                  this.itemNames[j]["Disty"] = productInfo.Disty;

                  if (typeof productInfo.DistyPrice != "undefined") {
                    this.itemNames[j]["DistyPrice"] = productInfo.DistyPrice;
                    this.itemNames[j]["DistyPrice_Format"] =
                      productInfo.DistyPrice_Format;
                  }
                  if (typeof productInfo.DistyAvailability != "undefined") {
                    this.itemNames[j]["DistyAvailability"] =
                      productInfo.DistyAvailability;
                    this.itemNames[j]["DistyAvailability_Format"] =
                      productInfo.DistyAvailability_Format;
                  }
                }

                if (this.autoCost == 1 || this.autoName == 1) {
                  if (this.autoCost == 1) {
                    // this.itemNames[j].price = productInfo.PRODUCTPRICE;
                    //this.itemNames[j].customerPrice = productInfo.PRODUCTPRICE;

                    if (productInfo.PRODUCTPRICE != 0) {
                      this.itemNames[j].price = this.itemNames[j].orgPrice =
                        productInfo.PRODUCTPRICE;

                      // recalculate customer price in case had a margin rate on this line
                      if (
                        this.$parent.isIndirect() &&
                        this.$parent.confirmedGlobalMargin &&
                        this.itemNames[j].marginRate != 0
                      ) {
                        // this.itemNames[j].margin = parseFloat((this.itemNames[j].price * this.itemNames[j].marginRate / 100).toFixed(this.$decimalsView));
                        // this.itemNames[j].customerPrice = parseFloat((this.itemNames[j].margin + this.itemNames[j].price).toFixed(this.$decimalsView));
                        this.itemNames[j].customerPrice =
                          this.getCustomerPriceByMarinRate(this.itemNames[j]);
                        this.itemNames[j].customerPrice = this.itemNames[
                          j
                        ].orgCustomerPrice = parseFloat(
                          this.itemNames[j].customerPrice.toFixed(
                            this.$decimalsView
                          )
                        );
                        this.reCalculateMargin(
                          this.itemNames[j],
                          "amount",
                          this.$decimalsView
                        );
                      }

                      //force event when changing price per
                      this.marginCalc1(j);
                    }
                  }
                  if (this.autoName == 1 && productInfo.PRODUCTNAME != "") {
                    this.itemNames[j].name = productInfo.PRODUCTNAME;
                  }
                  this.itemNames[j].contractPrice = productInfo.ContractPrice;
                  this.itemNames[j].contractPriceDate =
                    productInfo.ContractPriceDate;
                  // this.marginCalc1(j);
                }
              }
            }
            this.updateDistyTotal();
            this.checkDistyError();
          }
        } catch (err) {
          // console.log(err.message);
        } finally {
          this.$parent.loading = false;
        }
      }
    }

    // apply default EST%/ margin% for new order
    // if(this.$parent.$route.name == "OrderCreate") {
    // hp direct
    if (
      (this.$parent.sourceID == 1 && this.$parent.defaultESTPercent != 0) ||
      (this.$parent.sourceID == 12 && this.$parent.HPEdefaultESTPercent != 0)
    ) {
      if (this.$parent.sourceID == 12) {
        this.percent = this.$parent.HPEdefaultESTPercent;
      } else {
        this.percent = this.$parent.defaultESTPercent;
      }
      // this.applyDefaultESTPercent(this.$parent.defaultESTPercent);
      this.$parent.sourceRateMessage =
        "You are about to switch to <strong>" +
        this.$parent.source +
        " (EST = " +
        this.percent +
        "%)</strong>. Please confirm this change.";
      this.$parent.confirmedSourceRateVisible = true;
    }
    // other disty
    if (this.$parent.isIndirect()) {
      this.confirmApplySourceRate();
      /* const defaultDistyMarginRate = this.$parent.distyMarginRate.find(tmp => tmp.VARSOURCE_ID == this.$parent.sourceID);
        if(defaultDistyMarginRate && defaultDistyMarginRate.value != 0) {
          // this.applyDefaultDistyMarginRate(defaultDistyMarginRate.value);
          this.$parent.sourceRateMessage = "You are about to switch to <strong>" + this.$parent.source + " (Margin = " + defaultDistyMarginRate.value + "%)</strong>. Please confirm this change."
          this.$parent.confirmedSourceRateVisible = true;
        } */
    }
    // }
  }

  confirmApplySourceRate() {
    // if(this.$parent.$route.name == "OrderCreate") {
    // hp direct
    if (
      (this.$parent.sourceID == 1 && this.$parent.defaultESTPercent != 0) ||
      (this.$parent.sourceID == 12 && this.$parent.HPEdefaultESTPercent != 0)
    ) {
      this.applyDefaultESTPercent(this.percent);
    }
    // other disty
    // if(this.$parent.isIndirect()) {
    //   const defaultDistyMarginRate = this.$parent.distyMarginRate.find(tmp => tmp.VARSOURCE_ID == this.$parent.sourceID);
    //   if(defaultDistyMarginRate && defaultDistyMarginRate.value != 0) {
    //     this.applyDefaultDistyMarginRate(defaultDistyMarginRate.value);
    //   }
    // }
    // }

    this.$parent.confirmedSourceRateVisible = false;
    this.$parent.sourceRateMessage = "";
    this.updateItemSource();
  }

  applyDefaultESTPercent(defaultPercent) {
    // check which case should apply default rate?
    var canApply = true;
    if (canApply) {
      this.$parent.summary.estPercent = defaultPercent;
      for (let i in this.itemNames) {
        if (this.itemNames[i].isFee || this.itemNames[i].isContractFee) {
          continue;
        }
        this.itemNames[i].price = this.itemNames[i].orgPrice = 0;
        this.itemNames[i].margin = 0;
        this.itemNames[i].marginRate = 0;
      }
      this.updateSummary();
    }
  }

  applyDefaultDistyMarginRate(defaultPercent) {
    // check which case should apply default rate?
    var canApply = true;
    if (canApply) {
      this.$parent.globalMargin = defaultPercent;
      this.$parent.confirmedGlobalMargin = false;
      this.$parent.toggleConfirmGlobalMargin();
    }
  }

  getSKUCatTitle(item) {
    let ret = "";

    if (item.ISCONFIG || 0 || (item.ItemCategoryName || "") == "Config") {
      ret = "";
    } else if (item.ItemCategoryName || "") {
      ret = `Category: ${item.ItemCategoryName}`;
    } else {
      ret = "Category not available";
    }

    if (ret.length) {
      ret += ", ";
    }
    if (item.ItemPLName || "") {
      ret += `Product Line: ${item.ItemPLName.toUpperCase()}`;
    } else {
      ret += "Product Line not available";
    }

    return ret;
  }

  productCatTTVisibleIndex = "";
  async showProductCatTT(item, index, e) {
    // tmp stop process if fee tooltip is visible
    if (this.feeVisibleIndex == index) {
      return;
    }

    if (item.sku == "") {
      this.productCatTTVisibleIndex = "";
      item.ItemCategory = 0;
      item.ItemCategoryName = "";
      return;
    }
    if (this.productCatTTVisibleIndex == index) {
      return;
    }

    if (e.type == "click" || (e.type == "keydown" && this.autoName === 0)) {
      this.productCatTTVisibleIndex = index;
    }
  }

  async showFeeTooltip(item, index, e) {
    // not a fee?
    if (item.sku.toLowerCase() != "fee") {
      item.isFee = false;
    }

    if (
      item.sku == "" ||
      this.feeVisibleIndex == index ||
      item.sku.toLowerCase() != "fee"
    ) {
      this.feeVisibleIndex = "";
    } else if (item.sku.toLowerCase() == "fee") {
      this.feeVisibleIndex = index;
    }

    if (item.sku.toLowerCase() == "contractfee") {
      item.isContractFee = true;
      item.customerPrice = 0;
      item.marginRate = 0;
      item.margin = 0;
      item.quantity = 1;
      item.taxRate = 0;
      item.included = 0;
      // item.ItemCategory = 0;
      // item.ItemCategoryName = "";
      item.total = 0;
    } else {
      item.isContractFee = false;
    }
    if (item.isContractFee) {
      this.updateSummary();
    }
  }

  itemIsFee(item, isFee, index) {
    item.isFee = isFee;
    if (isFee) {
      item.customerPrice = 0;
      item.marginRate = 0;
      item.margin = 0;
      item.quantity = 1;
      item.taxRate = 0;
      item.included = 0;
      // item.ItemCategory = 0;
      // item.ItemCategoryName = "";
      item.total = 0;
      this.checkingSKU = false;
    }
    $(`#name_${index}`).focus();
    this.updateSummary();
  }

  closeConfirmItemIsFee(item, index) {
    this.feeVisibleIndex = "";
    const isFee = item.isFee || false;
    if (!isFee) {
      this.SKULookup(index, item);
    }
  }

  //calculate margin/marginRate
  reCalculateMargin(item, inCase, decimal = this.$decimals) {
    if (inCase == "rate") {
      if (item.price != 0 && item.customerPrice == 0) {
        item.marginRate = -100;
      } else if (item.price == 0 && item.customerPrice == 0) {
        item.marginRate = 0;
      } else if (item.price == 0) {
        item.marginRate =
          item.customerPrice > 0 ? 100 : item.customerPrice < 0 ? -100 : 0;
      } else {
        //just allow 4 decimals for rate
        var tmpRate = (item.margin / item.price) * 100;
        item.marginRate = parseFloat(tmpRate.toFixed(decimal));
        // if (tmpRate.toString().indexOf(".") != -1 && tmpRate.toString().split(".")[1].length > 4) {
        //   item.marginRate = parseFloat(tmpRate.toFixed(4));
        // }
      }
    } else if (inCase == "amount") {
      if (item.marginRate != 0 && item.marginRate != "") {
        item.margin = parseFloat(
          ((item.price * item.marginRate) / 100).toFixed(2)
        );
      } else {
        item.margin = 0.0;
      }
    }
  }

  getEST() {
    return this.$parent.summary.estPercent;
  }

  getSourceID() {
    return this.$parent.sourceID;
  }

  marginViewChange(viewType) {
    this.marginView = viewType;
    this.MarginViewTooltipVisible = false;
  }

  getLineItemTax(item) {
    return parseFloat((item.total * (item.taxRate / 100)).toFixed(2));
  }

  reCalculateGlobalMargin() {
    if (this.$parent.sourceID > 1) {
      //this.$parent.confirmedGlobalMargin === true
      var totalCustomerPrice = 0;
      // var totalCostPer = 0;
      var totalMargin = 0;
      var totalFee = 0;

      for (var i in this.itemNames) {
        let item = this.itemNames[i];
        if (
          typeof item.customerPrice == "number" &&
          !isNaN(item.quantity) &&
          !item.isFee
        ) {
          totalCustomerPrice += parseFloat(
            (item.customerPrice * item.quantity).toFixed(2)
          );
        }
        // if(typeof item.price == "number" && !isNaN(item.quantity) && !item.included && !item.isFee) {
        //   totalCostPer += parseFloat((item.price * item.quantity).toFixed(2));
        // }
        if (
          typeof item.margin == "number" &&
          !isNaN(item.quantity) &&
          !item.isFee
        ) {
          totalMargin += parseFloat((item.margin * item.quantity).toFixed(2));
          // let tmpMargin = ((item.price  * item.marginRate) / 100) * item.quantity;
          // if(isNaN(tmpMargin)) {
          //   tmpMargin = 0;
          // }
          // totalMargin += parseFloat(tmpMargin.toFixed(2));
        }
        if (typeof item.price == "number" && item.isFee) {
          totalFee += parseFloat(
            item.price.toFixed(this.$parent.expandDecimalPrecision)
          );
        }
      }

      totalCustomerPrice +=
        parseFloat(this.$parent.summary.totalShipping) +
        parseFloat(this.$parent.summary.totalTax);
      if (this.$parent.summary.includeVarCost == false) {
        totalMargin += parseFloat(this.$parent.summary.totalShipping);
      }
      // order margin = total margin / total customer price * 100
      if (totalCustomerPrice != 0) {
        this.$parent.globalMargin = parseFloat(
          (((totalMargin - totalFee) / totalCustomerPrice) * 100).toFixed(2)
        );
      } else {
        this.$parent.globalMargin = 0;
      }
      this.$parent.globalMarginBK = this.$parent.globalMargin;
    }
  }

  globalMarginChanged(e, forceUpdate = false) {
    if (e && e.key == "Enter") {
      e.preventDefault();
      $("li.globalMargin").find("input").addClass("displayNone");
      $("li.globalMargin").find("strong").removeClass("displayNone");
      // auto confirm global margin
      this.$parent.confirmedGlobalMargin =
        this.$parent.globalMargin != this.$parent.globalMarginBK
          ? true
          : this.$parent.confirmedGlobalMargin;
    }
    //go to tax box if press tab key
    if (e && e.key == "Tab") {
      e.preventDefault();
      $("li.tax").find("strong").addClass("displayNone");
      $("li.tax").find("input").removeClass("displayNone");
      setTimeout(() => {
        $("li.tax").find("input").focus().select();
      }, 100);
    }

    if (this.$parent.globalMargin == this.$parent.globalMarginBK) {
      //no changed
      return;
    }
    if ((e && (e.key == "Enter" || e.key == "Tab")) || forceUpdate) {
      setTimeout(() => {
        if (
          this.$parent.isIndirect() &&
          this.$parent.confirmedGlobalMargin === true
        ) {
          for (var i in this.itemNames) {
            let item = this.itemNames[i];
            if (!(item.isFee || item.isContractFee)) {
              item.marginRate = this.$parent.getMarkupFromMargin(
                this.$parent.globalMargin
              );
              // item.margin = parseFloat((item.price * item.marginRate / 100).toFixed(2));
              // item.customerPrice = item.margin + item.price;
              item.customerPrice = item.orgCustomerPrice =
                this.getCustomerPriceByMarinRate(item);
              this.reCalculateMargin(item, "amount");
              item.total = roundCents(item.customerPrice * item.quantity);
              //this.reCalculateMargin(item, "rate");
              item.tax = this.getLineItemTax(item);
            }
          }
          this.updateSummary();
          this.$parent.globalMarginBK = this.$parent.globalMargin;
        }
      }, 200);
    }
  }

  updateOrderTaxRate(newTaxRate, forceUpdate = false) {
    let updateDetailsViewData = false;

    // if user changed a tax rate for this order
    if (
      (newTaxRate != "" && this.$parent.currentTaxRate != newTaxRate) ||
      forceUpdate
    ) {
      this.$parent.currentTaxRate = newTaxRate;
      // let rowInd = 0;
      for (const i in this.itemNames) {
        const item = this.itemNames[i];

        // if (
        //   !this.isFee(item)
        //   &&
        //   ((this.isLastRow(rowInd) && !item.included) ||
        //     this.isEmptyRow(item, i))
        // ) {
        //   // case for last row/empty row
        //   // item.taxRate = newTaxRate;
        // } else {
        //   if (
        //     (item.included && (item.ISCONFIG || 0)) ||
        //     item.taxRate == 0 ||
        //     this.isFee(item)
        //   ) {
        //     item.taxRate = 0;
        //   } else {
        //     item.taxRate = newTaxRate;
        //   }
        // }

        // rowInd++;

        if (this.isFee(item)) {
          item.taxRate = 0;
        } else if ((item.taxRate || 0) != 0) {
          item.taxRate = newTaxRate;
        }
      }

      if (newTaxRate == 0) {
        this.checkAllTax = false;
      }

      if (this.detailsView == "sub-orders") {
        if (this.selectedIndexForTax != "") {
          const selectedItemForTax = this.itemNames[this.selectedIndexForTax];
          // if (!(selectedItemForTax.ISCONFIG || 0)) {
          selectedItemForTax.taxRate = newTaxRate;
          const configItems = this.getIncludedItems(
            selectedItemForTax,
            this.selectedIndexForTax
          ).filter(t => t.ISCONFIG || 0);
          for (const t of configItems) {
            t.taxRate = newTaxRate;
          }
          // }
          this.selectedIndexForTax = "";
        }
      } else if (this.detailsView == "sku-sum") {
        // apply check tax for a group of items
        if (this.selectedGroupItems.indexes.length) {
          for (const i of this.selectedGroupItems.indexes) {
            const selectedItemForTax = this.itemNames[i];
            selectedItemForTax.taxRate = newTaxRate;
            const configItems = this.getIncludedItems(
              selectedItemForTax,
              i
            ).filter(t => t.ISCONFIG || 0);
            for (const t of configItems) {
              t.taxRate = newTaxRate;
            }
          }
          this.selectedGroupItems.indexes = [];
          updateDetailsViewData = true;
        }
      }

      this.$parent.USECUSTOMTAX = 0;
      this.updateSummary();
    }

    // clear selected index for tax on a line item
    if (newTaxRate == "" || newTaxRate == 0) {
      this.selectedIndexForTax = "";
      this.selectedGroupItems.indexes = [];
    }

    // force update detailsViewData
    if (updateDetailsViewData) {
      this.changeDetailsView(this.detailsView, true);
    }

    if (!this.$parent.confirmedGlobalMargin) {
      this.reCalculateGlobalMargin();
    }
  }

  isLastRow(rowInd) {
    return rowInd == Object.keys(this.itemNames).length - 1 ? true : false;
  }

  isEmptyRow(item, i) {
    if (i == Object.keys(this.itemNames).length - 1) {
      // last row
      return true;
    }
    if (item.sku == "" && item.name == "" && item.quantity == 0) {
      return true;
    }

    return false;
  }

  checkItemChangedValue(index, type, oldVal: any, newVal: any) {
    if (this.itemNames[index].isFee || false) {
      return;
    }

    let id = "";
    if (type == "price") {
      id = `cust_cost_${index}`;
    } else if (type == "margin") {
      id = `margin_${index}`;
    }
    if (oldVal != newVal && id != "") {
      const item = $(`#${id}`).parent();
      item.removeClass("changedCalculation");
      setTimeout(() => {
        item.addClass("changedCalculation");
      }, 300);
    }
  }

  getCustomerPriceByMarinRate(item) {
    let ret = 0;
    if (item.marginRate == 100) {
      ret = item.price * 2;
    } else {
      // ret = (item.price * 100) / (100 - item.marginRate);
      const tmpPrice = !isNaN(parseFloat(item.price))
        ? parseFloat(item.price)
        : 0;
      const tmpMargin = (tmpPrice * (item.marginRate / 100)).toFixed(5);
      ret = tmpPrice + parseFloat(tmpMargin);
    }

    return parseFloat(ret.toFixed(5));
  }

  updateDistyTotal() {
    if (!this.$parent.isDynamic()) {
      return;
    }

    // reset disty total
    for (const i in this.$parent.distyIDs) {
      const distyID = this.$parent.distyIDs[i];
      this.$parent.DistyTotal[`${distyID}`] = {
        TotalPrice: 0,
        StockQty: 0,
        TotalQty: 0
      };
    }

    for (const i in this.itemNames) {
      const item = this.itemNames[i];
      if (item.quantity > 0 && typeof item.dynamicInfo != "undefined") {
        for (const j in this.$parent.distyIDs) {
          const distyID = this.$parent.distyIDs[j];
          this.$parent.DistyTotal[distyID].TotalPrice +=
            item.dynamicInfo[`Disty_${distyID}_Price`] * item.quantity || 0;
          this.$parent.DistyTotal[distyID].TotalQty += item.quantity;
          this.$parent.DistyTotal[distyID].StockQty +=
            item.dynamicInfo[`Disty_${distyID}_Availability`] >= item.quantity
              ? item.quantity
              : item.dynamicInfo[`Disty_${distyID}_Availability`];
        }
      }
    }
  }
  setIncludedItemPrice(updateSummary = true) {
    /* var parentPrice = 0;
    var parentCustomerPrice = 0;
    for(const lineIndex in this.itemNames) {
      var lineItem = this.itemNames[lineIndex];
      if(lineItem.included) {
        if(!this.$parent.includedItem) {
          this.$parent.includedItem = true;
        }
        const parent = findParent(lineIndex, this.itemNames);
        if (parent) {
          if(this.$parent.includedPrice) {
            parentPrice += lineItem.orgPrice;
            parentCustomerPrice += lineItem.orgCustomerPrice;
            parent.price = parentPrice;
            parent.customerPrice = parentCustomerPrice;
            parent.disable = true;
            lineItem.price = lineItem.orgPrice;
            lineItem.customerPrice = lineItem.orgCustomerPrice;
            lineItem.disable = false;
          } else {
            parent.price = parent.orgPrice;
            parent.customerPrice = parent.orgCustomerPrice;
            parent.disable = false;
            lineItem.price = 0;
            lineItem.customerPrice = 0;
            lineItem.quantity = 0;
            lineItem.disable = true;
            lineItem.margin = roundCentsWithDecimal(0);
          }
          parent.margin = roundCentsWithDecimal(parent.customerPrice - parent.price);
          this.reCalculateMargin(parent, "rate");
        }
      } else {
        parentPrice = 0;
        parentCustomerPrice = 0;
        lineItem.price = lineItem.orgPrice || 0;
        lineItem.customerPrice = lineItem.orgCustomerPrice || 0;
        lineItem.disable = false;
      }
      lineItem.margin = roundCentsWithDecimal(lineItem.customerPrice - lineItem.price);
      this.reCalculateMargin(lineItem, "rate");
    }
    if(updateSummary) {
      this.updateSummary();
    } */
  }
  findParentIndex(indexName, itemNames) {
    const index = parseInt(indexName.split("_")[1]);
    // find parent index
    for (let i = index - 1; i >= 1; i--) {
      const lineItem = itemNames[`productName_${i}`];
      if (!lineItem) {
        continue;
      }
      if (lineItem.included === 0) {
        return i;
      }
    }

    return 0;
  }

  updateItemCategory(index, info) {
    const item = this.itemNames[index] || null;
    if (item) {
      item.ItemCategory = info.ItemCategory;
      item.ItemCategoryName = info.ItemCategoryName;
      const row = this.$refs.rows.find(t => t.index == index);
      if (row) {
        row.$forceUpdate();
      }
    }
  }

  isParentLi(item, index) {
    let ret = false;
    // convert o array
    const itemNames: any = [];
    for (const i in this.itemNames) {
      itemNames.push({
        key: i,
        ...this.itemNames[i]
      });
    }

    // is parent if item.parentLiId = 0, and next item.parentLiId = item.quoteLiId
    const findIndex = itemNames.findIndex(t => t.key == index);
    const currentLine = itemNames[findIndex];
    const nextLine = itemNames[findIndex + 1];
    if (
      currentLine &&
      nextLine &&
      (((currentLine.quoteParentLiId || 0) == 0 &&
        (nextLine.quoteParentLiId || 0) > 0 &&
        (nextLine.quoteParentLiId || 0) == (currentLine.quoteLiId || 0)) ||
        ((currentLine.parentLiId || 0) == 0 &&
          (nextLine.parentLiId || 0) > 0 &&
          (nextLine.parentLiId || 0) == (currentLine.poLiID || 0)))
    ) {
      ret = true;
    }

    return ret;
  }

  lastGroupItem(index) {
    if (!this.itemNames[index] || !this.itemNames[index].included) {
      return false;
    }

    // check if next item is a base, then this is last item
    const keys = Object.keys(this.itemNames);
    for (let i = 0; i <= keys.length - 1; i++) {
      if (index == keys[i]) {
        const nextItem = this.itemNames[keys[i + 1]];
        if (nextItem && !nextItem.included) {
          return true;
        }
        break;
      }
    }

    return false;
  }

  hasConfigs(item, index) {
    if (item.included || 0) return false;

    let ret = false;
    const keys = Object.keys(this.itemNames);
    for (let i = 0; i <= keys.length - 1; i++) {
      if (index == keys[i]) {
        for (let j = i + 1; j <= keys.length - 1; j++) {
          const nextItem = this.itemNames[keys[j]];
          if (!nextItem) break;

          // check if this base has a config item
          if (!(nextItem.included || 0)) {
            // this is an other base
            break;
          } else if (nextItem.ISCONFIG || 0) {
            ret = true;
            break;
          }
        }

        break;
      }
    }

    return ret;
  }

  getBaseTotal(item, index) {
    const ret = {
      price: 0,
      customerPrice: 0,
      margin: 0,
      marginRate: 0,
      total: 0
    };
    const configItems = this.getIncludedItems(item, index).filter(
      t => t.ISCONFIG || 0
    );
    for (const t of configItems) {
      // cost
      ret.price += parseFloat(
        (t.price || 0).toFixed(this.$parent.expandDecimalPrecision)
      );
      ret.price = parseFloat(
        ret.price.toFixed(this.$parent.expandDecimalPrecision)
      );
      // price
      ret.customerPrice += parseFloat(
        (t.customerPrice || 0).toFixed(this.$parent.expandDecimalPrecision)
      );
      ret.customerPrice = parseFloat(
        ret.customerPrice.toFixed(this.$parent.expandDecimalPrecision)
      );
      // total
      ret.total += parseFloat(
        (t.total || 0).toFixed(this.$parent.expandDecimalPrecision)
      );
      ret.total = parseFloat(
        ret.total.toFixed(this.$parent.expandDecimalPrecision)
      );
    }

    // append base cost per/price per
    ret.price += parseFloat(
      (item.price || 0).toFixed(this.$parent.expandDecimalPrecision)
    );
    ret.price = parseFloat(
      ret.price.toFixed(this.$parent.expandDecimalPrecision)
    );
    ret.customerPrice += parseFloat(
      (item.customerPrice || 0).toFixed(this.$parent.expandDecimalPrecision)
    );
    ret.customerPrice = parseFloat(
      ret.customerPrice.toFixed(this.$parent.expandDecimalPrecision)
    );
    ret.total += parseFloat(
      (item.total || 0).toFixed(this.$parent.expandDecimalPrecision)
    );
    ret.total = parseFloat(ret.total.toFixed(2));
    // ret.total = parseFloat(
    //   (ret.customerPrice * (item.quantity || 0)).toFixed(2)
    // );

    // margin
    ret.margin = parseFloat((ret.customerPrice - ret.price).toFixed(2));
    // marginRate
    if (ret.margin == 0) {
      ret.marginRate = 0;
    } else if (ret.price == 0) {
      ret.marginRate =
        ret.customerPrice > 0 ? 100 : ret.customerPrice < 0 ? -100 : 0;
    } else {
      //just allow 4 decimals for rate
      ret.marginRate = parseFloat(
        ((ret.margin / ret.price) * 100).toFixed(this.$decimals)
      );
    }

    return ret;
  }

  // get included items of a base product
  getIncludedItems(item, index) {
    if (item.included) return [];

    const ret: any = [];
    const keys = Object.keys(this.itemNames);
    for (let i = 0; i <= keys.length - 1; i++) {
      if (index == keys[i]) {
        for (let j = i + 1; j <= keys.length - 1; j++) {
          const nextItem: any = this.itemNames[keys[j]];
          if (!nextItem || !nextItem.included) break;

          if (nextItem.included) {
            nextItem.indexKey = keys[j];
            ret.push(nextItem);
          }
        }

        break;
      }
    }

    return ret;
  }

  mouseEnterBase(type, item, index) {
    if (!this.hasConfigs(item, index)) {
      return;
    }

    // outline related config items
    const configItems = this.getIncludedItems(item, index).filter(
      t => t.ISCONFIG || 0
    );
    if (!configItems.length) return;

    // reset
    item.priceHover = 0;
    item.customerPriceHover = 0;
    for (const t of configItems) {
      t.priceHover = 0;
      t.customerPriceHover = 0;
    }

    if (type == "price") {
      item.priceHover = 1;
      for (const t of configItems) {
        t.priceHover = 1;
      }
    } else if (type == "customerPrice") {
      item.customerPriceHover = 1;
      for (const t of configItems) {
        t.customerPriceHover = 1;
      }
    }

    this.forceUpdateRows();
  }

  mouseLeaveBase(type, item, index) {
    if (!this.hasConfigs(item, index)) {
      return;
    }
    // reset
    item.priceHover = 0;
    item.customerPriceHover = 0;
    for (const t of this.getIncludedItems(item, index).filter(
      t => t.ISCONFIG || 0
    )) {
      t.priceHover = 0;
      t.customerPriceHover = 0;
    }
    this.forceUpdateRows();
  }

  baseQuantityChange(item, index) {
    const baseQtyNew = item.quantity || 0;
    const baseQtyBk = item.quantityBk || 0;
    if (!baseQtyNew) return;

    const configItems = this.getIncludedItems(item, index).filter(
      t => t.ISCONFIG || 0
    );
    if (!configItems.length) return;

    for (const t of configItems) {
      if (!t.quantity) {
        // config has no quantity, follow quantity of base
        t.quantity = t.quantityBk = baseQtyNew;
      } else if (baseQtyBk) {
        // check quantity per base of a config
        let qtyPerBase = 0;
        if (t.quantity % baseQtyBk == 0) {
          // is correct rule
          qtyPerBase = t.quantity / baseQtyBk;
          t.quantity = t.quantityBk = qtyPerBase * baseQtyNew;
        } else {
          // detect changed config quantity manually, keep this qty (or change later more for this case)
        }
      }
      t.total = (t.customerPrice || 0) * t.quantity;
    }
    item.quantityBk = baseQtyNew;
    this.forceUpdateRows();
  }

  configToggleChange(item, index) {
    // if set a base to config
    if (!item.included) {
      this.includeItem(index);
    }
    if (item.included && (item.ISCONFIG || 0)) {
      // if item is config, follow base taxRate
      const parent = findParent(index, this.itemNames);
      if (parent) {
        // item.quantity = item.quantityBk = parent.quantity || 0;
        item.taxRate = parent.taxRate || 0;
        item.total = (item.customerPrice || 0) * (item.quantity || 0);
      }
    }
    this.updateLineItems();
    this.updateSummary();
  }

  isFee(item) {
    return item.isFee || item.isContractFee || false;
  }

  isCancelReturn(item) {
    return item.statusid == 1 || item.statusid == 2 ? true : false;
  }

  forceUpdateRows() {
    if (!this.$refs.rows) {
      return;
    }

    for (const row of this.$refs.rows) {
      if (row) {
        row.$forceUpdate();
      }
    }
  }

  getSSTotal(subId) {
    const ret = {
      total: 0,
      totalFormatted: ""
    };
    if (!subId) return ret;

    if (this.convertFromMultiSubSets) {
      // case convert from multi quotes, or quote with a subset to order
      const quoteId = subId.split("-")[0];
      const subQuoteId = subId.split("-")[1];
      for (const i in this.itemNames) {
        const item = this.itemNames[i];
        if (item.quoteId == quoteId && item.subQuoteId == subQuoteId) {
          ret.total += item.total || 0;
          ret.total = parseFloat(ret.total.toFixed(2));
        }
      }
    } else {
      // create/edit order
      for (const i in this.itemNames) {
        const item = this.itemNames[i];
        if (item.subOrderId == subId) {
          ret.total += item.total || 0;
          ret.total = parseFloat(ret.total.toFixed(2));
        }
      }
    }
    ret.totalFormatted = dollarFormat(ret.total);

    return ret;
  }

  addNewSSLine(e, subId, item, index) {
    // fix: if add new line below base, move to the end of configs list
    let belowIndex = index;
    if (this.hasConfigs(item, index) && !(item.expanded || false)) {
      const keys = Object.keys(this.itemNames);
      for (let i = 0; i <= keys.length - 1; i++) {
        if (keys[i] == index) {
          // check next configs item
          for (let j = i + 1; j <= keys.length - 1; j++) {
            const config = this.itemNames[keys[j]];
            if (config && (config.ISCONFIG || 0)) {
              // below the last config of this item
              belowIndex = keys[j];
            } else {
              break;
            }
          }
        }
      }
    }

    const newItems = {};
    const subOrderId = subId;
    let isConvertCase = false;
    let quoteId = "";
    let subQuoteId = 0;
    if (`${subId}`.indexOf("-") != -1) {
      // convert to order, subId has format "quoteId-subQuoteId"
      isConvertCase = true;
      quoteId = subId.split("-")[0];
      subQuoteId = subId.split("-")[1];
    }

    let newIndex = "";
    for (const key in this.itemNames) {
      newItems[key] = this.itemNames[key];
      if (key == belowIndex) {
        let qty = 0;
        let included = item.included || 0;
        let ISCONFIG = item.ISCONFIG || 0;
        let includedparent = 0;
        let parentLiId = 0;
        let sourceId = item.sourceId || 0;

        if (this.hasConfigs(item, index)) {
          // add new line below a base sku
          qty = item.quantity || 0;
          included = 1;
          ISCONFIG = 1;
          includedparent = item.poLiID;
        } else if (item.included || 0) {
          // add new line below a included/config sku
          const parent: any = findParent(index, this.itemNames);
          if (parent) {
            // follow parent quantity if this item is set config
            if (item.ISCONFIG || 0) {
              qty = parent.quantity || 0;
              sourceId = parent.sourceId || 0;
            }
            includedparent = parent.poLiID || 0;
            parentLiId = parent.poLiID || 0;
          }
        }

        // add more line below this index
        newIndex = `productName_${this.getMaxIndex() + 1}`;
        newItems[newIndex] = {
          subOrderId,
          quoteId,
          subQuoteId,
          poLiID: 0,
          quoteLiId: 0,
          sku: "",
          name: "",
          price: 0,
          customerPrice: 0,
          margin: 0,
          marginRate: this.$parent.getConfirmedGlobalMargin(true),
          quantity: qty,
          tax: 0,
          taxRate: 0,
          total: 0,
          included,
          ISCONFIG,
          includedparent,
          parentLiId,
          selectedSource: [sourceId],
          sourceId: sourceId
        };
        this.length += 1;
      }
    }
    this.itemNames = newItems;
    // auto focus sku box
    this.$nextTick().then(() => {
      if (newIndex != "" && $(`#sku_${newIndex}`).length) {
        $(`#sku_${newIndex}`).focus();
      }
    });
    this.updateLineItems();
  }

  get subsetIds() {
    // can be subQuoteIds, or subOrderIds, so name this is "subsetIds"
    let ret: any = [];
    if (this.convertFromMultiSubSets) {
      // case convert from multi quotes, or quote with a subset to order
      // specify how many subsets
      let tmpSubIds: any = [];
      for (const item of this.$parent.splitByQuoteId) {
        for (const t of item.items) {
          tmpSubIds.push(`${t.QUOTEID}-${t.SUBQUOTEID}`);
        }
      }
      tmpSubIds = [...new Set(tmpSubIds)];
      let index = 0;
      for (const ssId of tmpSubIds) {
        index++;
        const quoteId = ssId.split("-")[0];
        const subQuoteId = ssId.split("-")[1];
        const euInfo =
          (this.$parent.quoteEndUsers || []).find(
            t => t.SUBQUOTEID == subQuoteId && t.QUOTEID == quoteId
          ) || {};
        if (euInfo && euInfo.EUNAME == "") {
          euInfo.EUNAME = `End User ${index}`;
        }
        const euCD = this.$parent.dataFields
          .filter(t => t.SUBQUOTEID == subQuoteId && t.QUOTEID == quoteId)
          .map(t => ({
            customFieldId: t.CUSTOMFIELDID,
            customFieldName: t.CUSTOMFIELDNAME,
            customDtype: t.CUSTOMDTYPE || 0,
            customValue: t.CUSTOMVALUE,
            isEUData: 1,
            options: t.OPTIONS || []
          }));
        // let euCD = [];
        // let inSubQuote = this.$parent.dataFields.filter(t => t.SUBQUOTEID == subQuoteId && t.QUOTEID == quoteId);
        // inSubQuote.map(cf => {
        //   let inList = euCD.filter(t => t.customFieldId == cf.CUSTOMFIELDID);
        //   if (!inList.length) {
        //     let options = cf.CUSTOMDTYPE == 3 ? inSubQuote.filter(t => t.CUSTOMFIELDID == cf.CUSTOMFIELDID) : [];
        //     euCD.push(
        //       {
        //         customFieldId: cf.CUSTOMFIELDID,
        //         customFieldName: cf.CUSTOMFIELDNAME,
        //         customDtype: cf.CUSTOMDTYPE || 0,
        //         customValue: cf.CUSTOMVALUE,
        //         isEUData: 1,
        //         options: options.map(t => (
        //           {
        //             CUSTOMFIELDOPTIONID: t.CUSTOMFIELDOPTIONID,
        //             CUSTOMFIELDOPTIONNAME: t.CUSTOMFIELDOPTIONNAME
        //           }
        //         ))
        //       }
        //     )
        //   }
        // });

        ret.push({
          subId: ssId != "-0" ? ssId : 0,
          quoteId,
          subQuoteId,
          euInfo,
          euCD
        });
      }
    } else {
      // edit case, or normal order create process
      ret = this.$parent.splitBySubOrderId.map(item => ({
        ...item,
        subId: item.subOrderId || 0
      }));
    }

    return ret;
  }

  get convertFromMultiSubSets() {
    return this.$route.name == "OrderCreate" && this.$parent.isMultiEndUsers;
  }

  getMaxIndex() {
    //get max index
    const indArr: number[] = [];
    let maxInd = 0;
    $.each(this.itemNames, function (i, val) {
      indArr.push(parseInt(i.replace("productName_", "")));
    });
    if (indArr.length) {
      maxInd = Math.max.apply(Math, indArr);
    }

    return maxInd;
  }

  isEndItem(index) {
    const keys = Object.keys(this.itemNames);
    const lastKey = keys.pop();
    return lastKey === index;
  }

  idxOf(index: string): number {
    return Object.keys(this.itemNames).indexOf(index);
  }

  configsCount(item, index) {
    const configItems = this.getIncludedItems(item, index).filter(
      t => t.ISCONFIG || 0
    );
    return configItems.length;
  }

  showEUData(ss) {
    this.selectedSubOrder = ss;
    this.euDataVisible = true;
    this.hideApiCall = false;
    if (
      (this.$route.query.quoteIds || "").length ||
      this.$parent.duplicateOrder
    ) {
      this.hideApiCall = true;
    }
  }

  doSearchEu(value) {
    // show full subset
    this.pageNumber = Math.ceil(this.subsetIds.length / this.subsetPerPage);
    this.filters.name = value;
  }

  isEuFilter(value) {
    if (!this.filters.name) {
      return true;
    }
    return value.toLowerCase().includes(this.filters.name.toLowerCase());
  }

  updateLineItems() {
    const keys = Object.keys(this.itemNames);
    let parent: any = null;
    let previousItem: any = null;
    for (let i = 0; i <= keys.length - 1; i++) {
      const item: any = this.itemNames[keys[i]];
      item.configsCnt = 0;
      item.isLastGroupItem = false;
      if (!(item.included || 0)) {
        // is base
        parent = item;
        if (previousItem) {
          // mark previous item is last group item
          previousItem.isLastGroupItem = true;
        }
      } else {
        previousItem = item;
        if ((item.ISCONFIG || 0) && parent) {
          // is config item
          // calculate configs total
          parent.configsCnt += 1;
        }
      }
    }
    this.forceUpdateRows();
  }

  get hasMultiSources() {
    return this.$parent.selectedSources.length > 1;
  }

  updateItemSource() {
    if (this.$parent.selectedSources.length != 1) {
      return;
    }
    const inList = this.$parent.sourcesList.find(
      item => item.ID == this.$parent.selectedSources[0]
    );
    if (!inList) return;

    const sourceId = this.$parent.selectedSources[0];
    for (let i in this.itemNames) {
      this.itemNames[i].selectedSource = [sourceId];
      this.itemNames[i].sourceId = sourceId;
    }
  }

  clearSelectedDisty(item) {
    if (this.$parent.isDynamic() && typeof item.dynamicInfo != "undefined") {
      item.dynamicInfo.selectedDistyID = 0;
      // this.$forceUpdate();
    }
  }

  toggleDetailsDropdown() {
    if (!this.$parent.isMultiEndUsers) return;

    this.$nextTick(() => {
      (this.$refs.detailsDropdown as HTMLSelectElement).focus();
    });
  }

  async changeDetailsView(view = "", force = false) {
    const currentView = this.detailsView;
    if ((view == "" || view == this.detailsView) && !force) return;

    this.detailsView = view;
    if (view == "sku-sum") {
      // backup the current state
      const detailsViewDataBk: any =
        currentView == view ? [...this.detailsViewData] : [];

      const detailsViewData: any = [];
      const itemNamesArr: any = [];
      for (const i in this.itemNames) {
        const item = this.itemNames[i];
        if (!item.sku || this.isFee(item) || [1, 2].includes(item.statusid)) {
          continue;
        }

        itemNamesArr.push({
          indexKey: i,
          sku: item.sku,
          name: item.name,
          quantity: parseInt(`${item.quantity}`) || 0,
          included: item.included || 0,
          ISCONFIG: item.ISCONFIG || 0,
          price: item.price || 0,
          customerPrice: item.customerPrice || 0,
          margin: item.margin || 0,
          marginRate: item.marginRate || 0,
          taxRate: item.taxRate || 0,
          sourceId: item.sourceId || 0
        });
      }

      // group by skus
      const processedSKUs: string[] = [];
      let cnt = 0;
      for (const base of itemNamesArr) {
        if (base.ISCONFIG || processedSKUs.includes(base.sku.toLowerCase())) {
          continue;
        }

        cnt++;
        processedSKUs.push(base.sku.toLowerCase());
        let qty = 0;
        const configItems: any = [];
        const baseProductId = cnt;
        const indexes: any = [];
        let selectedSource: number[] = [];

        // check base/grouped products have same sku
        const sameSKUs = itemNamesArr.filter(
          item =>
            !(item.ISCONFIG || 0) &&
            item.sku.toLowerCase() == base.sku.toLowerCase()
        );
        for (const item of sameSKUs) {
          // collect group info
          indexes.push(item.indexKey);
          if (!selectedSource.includes(item.sourceId || 0)) {
            selectedSource.push(item.sourceId || 0);
          }
          // total base quantity
          qty += item.quantity;

          // group configs
          const configs = this.getIncludedItems(
            this.itemNames[item.indexKey],
            item.indexKey
          ).filter(t => t.ISCONFIG || 0);
          for (const config of configs) {
            const inList: any = configItems.find(
              t => t.sku.toLowerCase() == config.sku.toLowerCase()
            );
            const configQty = parseInt(`${config.quantity}`) || 0;
            if (!inList) {
              configItems.push({
                sku: config.sku,
                name: config.name,
                quantity: configQty,
                included: 1,
                ISCONFIG: 1,
                price: config.price,
                customerPrice: config.customerPrice,
                margin: config.margin || 0,
                marginRate: config.marginRate || 0,
                taxRate: config.taxRate || 0,
                selectedSource: [],
                sourceId: 0,
                baseProductId,
                total: (config.customerPrice || 0) * configQty,
                indexes: [config.indexKey]
              });
            } else {
              inList.quantity += configQty;
              inList.total =
                (inList.customerPrice || 0) * (inList.quantity || 0);
              inList.indexes.push(config.indexKey);
            }
          }
        }

        // related base item in detailsViewDataBk
        const inDataBk = detailsViewDataBk.find(
          t => t.baseProductId == baseProductId && t.included == 0
        );
        const prevExpanded = inDataBk ? inDataBk.expanded || false : false;

        // calculate for some values after group
        const newBase = { ...base, indexes };
        delete newBase.indexKey;
        newBase.baseProductId = baseProductId;
        newBase.included = 0;
        newBase.quantity = qty;
        newBase.total = (newBase.customerPrice || 0) * qty;
        newBase.configsCnt = configItems.length;
        newBase.selectedSource = [];
        newBase.sourceId = 0;
        newBase.expanded = prevExpanded;
        if (selectedSource.length == 1 && selectedSource[0] != 0) {
          newBase.selectedSource = selectedSource;
          newBase.sourceId = selectedSource[0];
        } else if (selectedSource.length > 1) {
          // just note selected multi sources
          newBase.noteDiffSources = selectedSource;
        }
        detailsViewData.push(newBase);
        detailsViewData.push(...configItems);
      }

      this.detailsViewData = detailsViewData;
    }
  }

  groupItemsChange(type = "", groupItems: any = {}, options: any = {}) {
    const indexes = groupItems.indexes || [];
    if (!type || !indexes.length) return;

    switch (type) {
      case "cost":
        // sync with itemNames
        for (const i of indexes) {
          const item = this.itemNames[i];
          item.price = groupItems.price;
          this.clearSelectedDisty(item);
          this.calcWithChangeCostPrice(i);
        }
        break;
      case "price":
        // sync with itemNames
        for (const i of indexes) {
          const item = this.itemNames[i];
          item.customerPrice = groupItems.customerPrice;
          this.marginCalc1(i);
        }
        break;
      case "margin_per":
        // sync with itemNames
        for (const i of indexes) {
          const item = this.itemNames[i];
          item.margin = groupItems.margin;
          this.marginCalc2(i);
        }
        break;
      case "marginRate":
        // sync with itemNames
        for (const i of indexes) {
          const item = this.itemNames[i];
          item.marginRate = groupItems.marginRate;
          this.marginRate(i);
        }
        break;
      case "marginRateBlur":
        if (isNaN(groupItems.marginRate)) {
          groupItems.marginRate = 0;
        }

        // sync with itemNames
        for (const i of indexes) {
          const item = this.itemNames[i];
          item.marginRate = groupItems.marginRate;
          this.marginRate(i);
        }
        break;
      case "tax":
        // auto show tax rate tooltip
        if (
          (groupItems.taxRate == 0 && this.$parent.currentTaxRate == 0) ||
          this.$parent.USECUSTOMTAX
        ) {
          this.$parent.checkAutomateTaxRate();
          this.taxRateVisible = true;
          this.selectedGroupItems.indexes = indexes;
          return;
        }

        // sync with itemNames
        for (const i of indexes) {
          const item = this.itemNames[i];
          item.taxRate = groupItems.taxRate;
          this.itemTaxable(i);
        }
        break;
      case "source":
        groupItems.selectedSource = [options.id];
        groupItems.sourceId = options.id;
        if ((groupItems.noteDiffSources || []).length) {
          // reset note selected multi sources
          groupItems.noteDiffSources = [];
        }
        // sync with itemNames
        for (const i of indexes) {
          const item = this.itemNames[i];
          item.selectedSource = [options.id];
          item.sourceId = options.id;
        }

        break;
    }

    // force update detailsViewdata
    this.changeDetailsView(this.detailsView, true);
  }
}
